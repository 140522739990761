import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/lab/Slider'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  slider: {
    padding: '22px 0',
  },
  thumb: {
    width: 20,
    height: 20
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', 
    marginTop: 8,
  },
  smallLabel: {
    fontSize: '0.875rem', 
    marginRight: 15, 
    color: theme.palette.text.default
  },
  largeLabel: {
    fontSize: '1.5rem', 
    marginLeft: 15, 
    color: theme.palette.text.default
  }
})

const FontSizeSlider = ({
  classes,
  value,
  onChange,
  firstLabel = 'A',
  secondLabel = 'A',
  min = 0.8,
  max = 1.6,
  step = 0.2,
  width = 200,
  margin = '0 auto'
}) => {
  const handleChange = (event, newValue) => {
    onChange(newValue)
  }

  return (
    <div className={classes.root} style={{ width, margin }}>
      <div className={classes.labelContainer}>
        <Typography className={classes.smallLabel}>{firstLabel}</Typography>
        <Slider
          classes={{
            root: classes.slider,
            thumb: classes.thumb,
            track: classes.track,
          }}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          marks={[]} 
        />
        <Typography className={classes.largeLabel}>{secondLabel}</Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(FontSizeSlider)