import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../../Icon'
import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'

import {

} from '../../../actions'

const styles = theme => ({
  disabled: {
    opacity: '.5'
  },
  button: {
    marginTop: -9
  },
  buttonIcon: {
    marginRight: '.5rem'
  },
  multiButtonContainer: {
    marginTop: -9,
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    display: 'flex'
  },
  multiButton: {
    flex: 1,
    margin: 0,
    borderRadius: 0
  },
  multiButtonPrimary: {
    backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
      opacity: 0.9
    }
  },
  multiButtonSecondary: {
    backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
      opacity: 0.9
    }
  }
})

class Submit extends React.Component {
  onClick = (button) => {
    const { onSubmit, index } = this.props

    switch (button.type) {
      case 'reset':
        return onSubmit(button.actionType, index, 'resetPage', button)
      default:
        return onSubmit(button.actionType, index, null, button)
    }
  }

  render() {
    const { field, enabled, classes, theme } = this.props

    const { actionType, readonly } = field

    const getButton = (theButton, className, key) => {
      const disabled = (actionType === 'selected' && !enabled) || readonly

      const tooltip = disabled ? theButton.disabledTooltip || theButton.tooltip : theButton.tooltip

      if (theButton.type === 'multibutton') {
        return (
          <div className={classes.multiButtonContainer}>
            {theButton.buttons.map((innerButton, index) => {
              const buttonClassName = `${classes.multiButton} ${index === 0 ? classes.multiButtonPrimary : classes.multiButtonSecondary}`

              return getButton(innerButton, buttonClassName, index)
            })}
          </div>
        )
      }
    
      const getButtonStyle = (button, theme) => {
        const buttonType = button.buttonType || 'default'  
  
        return {
          ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
          ...(button.style || {}),
          ...(button.styles || {})
        }
      }

      return (
        <Tooltip key={key} TransitionComponent={Zoom} title={tooltip || ''} classes={{ tooltip: classes.tooltipRoot }}>
          <Button fullWidth variant='contained' style={getButtonStyle(theButton, theme)} className={[classes.button, (disabled ? classes.disabled : null), className].filter(el => !!el).join(' ')} disabled={disabled} onClick={() => this.onClick(theButton)}>
            {theButton.icon ? (
              <Icon className={classes.buttonIcon}>{theButton.icon}</Icon>
            ) : ''}
            {theButton.label}
          </Button>
        </Tooltip>
      )
    }

    return getButton(field)
  }
}

Submit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Submit)))
