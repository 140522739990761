import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../actions'

const styles = theme => ({
  divider: {
    display: 'block',
    minWidth: 'calc(100% - 1rem)',
    borderTop: '1px solid' + theme.palette.border.table,
    margin: '0 .5rem 1rem'
  },
  empty: {
    display: 'block',
    minWidth: 'calc(100% - 1rem)',
  }
})

class Divider extends React.Component {
  render() {
    const { classes, action } = this.props

    return (
      <div className={action.empty ? classes.empty : classes.divider}></div>
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Divider)))
