import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.default,
    margin: 0
  },
  input: {
    backgroundColor: theme.palette.background.default,
    padding: '.375rem .75rem',
    '&:disabled': {
      backgroundColor: theme.palette.background.disabled,
      cursor: 'not-allowed'
    }
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 9px) !important',
    fontSize: '.75rem',
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out, opacity .3s ease-in-out',
      opacity: 0
    }
  },
  labelShrink: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -5px) !important',
    '&::after': {
      right: -5,
      top: '50%',
      opacity: 1
    }
  }
})

class String extends React.Component {
  state = {
    value: ''
  }

  componentDidMount = () => {
    return this.setState({
      value: this.props.cell.value
    })
  }

  onChange = (event) => {
    const { onChange, fieldPath } = this.props

    const { value } = event.target

    onChange(value, fieldPath)

    this.setState({
      value
    })
  }

  componentDidUpdate = () => {
    const { onChange, fieldPath, cell } = this.props

    const { value: currentValue } = cell

    const { value } = this.state

    if (currentValue !== value) {
      this.setState({
        value: currentValue
      }, () => {
        onChange(currentValue, fieldPath)
      })
    }
  }

  render() {
    const { classes, cell, isActive, readonly } = this.props

    const { name, label, inputType = 'text', multiline = false, hidden = false } = cell

    const { value } = this.state

    if (hidden) {
      return ''
    }

    return (
      <div className={classes.root}>
        <TextField
          variant='outlined'
          fullWidth={true}
          disabled={!isActive}
          type={inputType}
          name={name}
          value={value || ''}
          label={label || ''}
          multiline={multiline}
          rows={5}
          onChange={this.onChange}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.labelShrink,
            }
          }}
          inputProps={{
            className: multiline ? '' : classes.input,
            readOnly: readonly || (cell.readonly ? true : false)
          }} />
      </div>
    )
  }
}

String.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(String)))
