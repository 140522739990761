import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '../Icon'

import {
} from '../../actions'

const styles = theme => ({
  root: {
    boxShadow: theme.palette.shadow.counter,
    borderRadius: '10px !important',
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterIcon: {
    fontSize: '3rem',
  },
  counterValue: {
    fontSize: '2rem',
    fontWeight: 900,
  },
  counterSubtitle: {
    color: '#999999',
    fontSize: '1rem',
    fontWeight: 600
  }
})

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))

  return `rgba(${r},${g},${b},${alpha})`
}

class Counter extends React.Component {

  render() {
    const { classes, item } = this.props

    const { icon, label, value, color = '#fffff', size } = item

    return (
      <div size={size} className={classes.root} style={{'backgroundColor': hex2rgba(color, 0.2)}}>
        {icon ? (
          <div className={classes.counterIcon}>
            <Icon className={classes.icon} color={color}>{icon}</Icon>
          </div>
        ) : ''}
        <div className={classes.counterValue}>
          {value.toLocaleString()}
        </div>
        {label ? (
          <div className={classes.counterSubtitle}>
            {label}
          </div>
        ) : ''}
      </div>
    )
  }
}

Counter.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Counter)))
