import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUISelect from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import _ from 'lodash'

import {} from '../../../actions'

const styles = (theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 2,
      top: '50%',
      marginTop: -1,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, 0px)',
    padding: '0 3px'
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (config, isColored, theme) => {
  const newCustomStyles = config.customStyles || {}

  const defaultCustomStyles = {
    control: (provided, state) => ({}),
    menu: (provided, state) => ({}),
    dropdownIndicator: (provided, state) => ({}),
    singleValue: (provided, state) => ({}),
    valueContainer: (provided, state) => ({}),
    indicatorSeparator: (provided, state) => ({}),
    option: (provided, state) => ({}),
    input: (provided, state) => ({}),
    ...newCustomStyles
  }

  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : (state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover),
      borderRadius: 4,
      backgroundColor: state.isDisabled ? theme.palette.background.disabled : (isColored ? theme.palette.background.colored : theme.palette.background.controlSelect),
      opacity: 1,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : theme.palette.select.inputControlBorder,
      },
      ...defaultCustomStyles.control(provided, state)
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 100,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      ...defaultCustomStyles.menu(provided, state)
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
      ...defaultCustomStyles.dropdownIndicator(provided, state)
    }),
    multiValue: (provided, state) => ({
      ...provided,
      margin: '.5rem 0 0',
      flexGrow: 1,
      width: '100%',
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.singleValue(provided, state),
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: 0,
      flexGrow: 1,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.singleValue(provided, state),
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.valueContainer(provided, state),
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
      ...defaultCustomStyles.indicatorSeparator(provided, state),
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      color: theme.palette.select.text,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: theme.palette.select.inputControlElementSelected,
      },
      '&:hover': {
        backgroundColor: theme.palette.select.inputControlElementHover,
      },
      ...defaultCustomStyles.option(provided, state)
    }),
    input: (provided, state) => ({
      ...provided,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.input(provided, state)
    })
  }
}

class Select extends React.Component {
  state = {
    error: false,
    errorMessage: null,
    focused: false,
    selectedValue: ''
  }

  render() {
    const { classes, onChange, config, theme } = this.props

    const { options, name, label, placeholder, noOptionsMessage, isClearable = false } = config

    const { focused } = this.state

    const { selectedValue } = this.state

    const activeLabel = selectedValue ? true : focused

    const labelActive = (activeLabel ? ' ' + classes.labelActive : '')

    return (
      <FormControl fullWidth={true} className={classes.root}>
        {label ? (
          <InputLabel variant='outlined' className={classes.label + labelActive}>{label}</InputLabel>
        ) : ''}
        <MUISelect
          variant='outlined'
          styles={customStyles({
            error: false,
            customStyles: {}
          }, selectedValue && selectedValue.isColored, theme)}
          options={options}
          value={selectedValue || ''}
          inputId={_.uniqueId()}
          placeholder={placeholder || false}
          isClearable={isClearable}
          onChange={(value) => {
            this.setState({
              selectedValue: value
            })

            return onChange(name, (value || {}).value || '')
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          noOptionsMessage={() => noOptionsMessage ? noOptionsMessage.noResults : null} />
      </FormControl>
    )
  }
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Select)))
