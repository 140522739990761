import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '../Icon'
import List from '@material-ui/core/List'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ListElement from './ListElement'
import Divider from '@material-ui/core/Divider'

import {
  appActions
} from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    minWidth: 0,
    maxWidth: theme.appVars.sidebarWidth.main,
    transition: 'min-width .3s ease-in-out, box-shadow .3s ease-in-out',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 101,
    backgroundColor: theme.palette.background.sidebarRoot,
  },
  open: {
    minWidth: theme.appVars.sidebarWidth.open,
    maxWidth: theme.appVars.sidebarWidth.open,
    boxShadow: theme.shadows[3],
  },
  icon: {
    transform: 'scale(0)',
    transition: 'transform .3s ease-in-out',
    width: 24,
    height: 24,
    fontSize: 24,
    textAlign: 'center'
  },
  openIcon: {
    position: 'absolute',
    transform: 'scale(1)'
  },
  navButtonContainer: {
    padding: '8px 0',
    textAlign: 'right',
    backgroundColor: theme.palette.background.navButtonContainer,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, .25)',
    position: 'relative',
    zIndex: 1,
  },
  navButton: {
    color: theme.palette.text.sidebar,
  },
  menu: {
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    opacity: 0.3
  }
})

class Sidebar extends React.Component {
  render() {
    const { classes, sidebarConfig, sidebarIsOpen } = this.props

    return (
      <div className={classes.root + (sidebarIsOpen ? ' ' + classes.open : '')}>
        <div className={classes.navButtonContainer}>
          <IconButton onClick={this.props.onToggleSidebar} className={classes.navButton}>
            <Icon className={(!sidebarIsOpen ? classes.openIcon : '') + ' ' + classes.icon}>bars</Icon>
            <Icon className={(sidebarIsOpen ? classes.openIcon : '') + ' ' + classes.icon}>chevron-left</Icon>
          </IconButton>
        </div>
        <Scrollbars style={{ height: '100%' }} >
          <List className={classes.menu}>
            {sidebarConfig.map((item, index) => {
              if (item.type === 'divider') {
                return (
                  <Divider className={classes.divider} key={index} />
                )
              }

              return (
                <ListElement item={{ ...item, index }} isFirst={index === 0} key={index} />
              )
            })}
          </List>
        </Scrollbars>
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onToggleSidebar: () => {
    dispatch(appActions.toggleSidebar())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Sidebar)))
