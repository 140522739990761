import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Default from './Default'
import Divider from './Divider'
import Import from './Import'
import InlineForm from './InlineForm'
import Label from './Label'
import List from './List'
import Nav from './Nav'

import {} from '../../actions'

const styles = theme => ({
  divider: {
    display: 'block',
    minWidth: 'calc(100% - 1rem)',
    borderTop: '1px solid' + theme.palette.border.table,
    margin: '0 .5rem 1rem'
  },
})

const actionList = {
  divider: Divider,
  import: Import,
  inlineForm: InlineForm,
  label: Label,
  list: List,
  nav: Nav
}

class BaseAction extends React.Component {
  render() {
    const { action, enabled, onClick, index, selectedElements, pageType, pageTypes, isTop } = this.props

    const TmpAction = actionList[action.type] || Default

    return (
      <TmpAction {...{action, enabled, onClick, index, selectedElements, isTop, pageType, pageTypes}} />
    )
  }
}

BaseAction.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BaseAction)))
