import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '../Icon'

import {} from '../../actions'

const styles = theme => ({
  button: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    transition: 'opacity .3s ease-in-out'
  },
  label: {
    marginLeft: '.5rem'
  },
  disabled: {
    opacity: '.5'
  },
  multiButtonContainer: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    display: 'flex'
  },
  multiButton: {
    flex: 1,
    margin: 0,
    borderRadius: 0
  },
  multiButtonPrimary: {
    backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
      opacity: 0.9
    }
  },
  multiButtonSecondary: {
    backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
      opacity: 0.9
    }
  }
})

class Default extends React.Component {
  render() {
    const { classes, onClick, action, enabled, theme } = this.props

    const getButton = (button, className, key) => {
      if (button.type === 'multibutton') {
        return (
          <div className={classes.multiButtonContainer}>
            {button.buttons.map((innerButton, index) => {
              const buttonClassName = `${classes.multiButton} ${index === 0 ? classes.multiButtonPrimary : classes.multiButtonSecondary}`

              return getButton(innerButton, buttonClassName, index)
            })}
          </div>
        )
      }

      const getButtonStyle = (button, theme) => {
        const buttonType = button.buttonType || 'default'  
  
        return {
          ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
          ...(button.style || {}),
          ...(button.styles || {})
        }
      }

      return (
        <Tooltip TransitionComponent={Zoom} title={button.tooltip || ''} key={key} classes={{ tooltip: classes.tooltipRoot }}>
          <Button variant='contained' disabled={!enabled} className={[classes.button, className, !enabled ? classes.disabled : null].filter(el => !!el).join(' ')} style={getButtonStyle(button, theme)} onClick={() => onClick(button)}>
            {button.icon ? (
              <Icon>{button.icon}</Icon>
            ) : null}
            <span className={classes.label}>{button.label}</span>
          </Button>
        </Tooltip>
      )
    }

    return getButton(action)
  }
}

Default.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Default)))
