import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MUIButton from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../Icon'
import LabelWithAsterisk from './LabelWithAsterisk'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 0,
  },
  label: {
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: '.25rem'
  },
  button: {
    minWidth: 40,
    marginBottom: 0,
  },
  disabled: {
    opacity: '.5'
  },
  multiButtonContainer: {
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    display: 'flex'
  },
  multiButton: {
    flex: 1,
    borderRadius: 0
  },
  icon: {
    marginRight: 8
  },
  multiButtonPrimary: {
    backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
      opacity: 0.9
    }
  },
  multiButtonSecondary: {
    backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
      opacity: 0.9
    }
  }
})

class Buttons extends React.Component {
  render() {
    const { classes, field, onChange, readonly, appConfig, match, theme } = this.props

    const getActionProps = (button) => {
      const { action, actionType, updateRelated, saveBeforeAction, urlType } = button

      let actionProps = {
        onClick : () => {
          return onChange({type: 'string'}, {
            ...field,
            updateRelated
          }, '')
        }
      }

      if (action) {
        switch (actionType) {
          case 'navlink':
            actionProps = {
              component: NavLink,
              to: appConfig.appPrefix + action
            }
            break
          case 'download':
            actionProps = {
              href: action,
              target: '_blank',
              rel: 'noopener noreferrer',
              download: field.download
            }

            if (urlType === 'api') {
              actionProps.href = appConfig.apiUrl + actionProps.href
            }

            break
          case 'print':
            actionProps = {
              onClick : () => {
                if (saveBeforeAction) {
                  return onChange({type: 'saveBeforeAction'}, {
                    callback: this.props.onPrintPost,
                    callbackProps: [action, {}],
                    params: match.params
                  }, '')
                }

                return this.props.onPrintPost(action, {})
              }
            }
            break
          default:
        }
      }

      return actionProps
    }

    return (
      <div className={classes.root}>
        {field.label ? (
          <div className={classes.label}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></div>
        ) : null}
        {field.buttons.map((button, index) => {
          const getButton = (theButton, className, key) => {
            if (theButton.type === 'multibutton') {
              return (
                <div key={index} className={classes.multiButtonContainer}>
                  {button.buttons.map((innerButton, innerIndex) => {
                    const buttonClassName = `${classes.multiButton} ${innerIndex === 0 ? classes.multiButtonPrimary : classes.multiButtonSecondary}`

                    return (
                      getButton(innerButton, buttonClassName, innerIndex)
                    )
                  })}
                </div>
              )
            }

            const getButtonStyle = (button, theme) => {
              const buttonType = button.buttonType || 'default'  
        
              return {
                ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
                ...(button.style || {}),
                ...(button.styles || {})
              }
            }

            const actionProps = getActionProps(theButton)

            return (
              <Tooltip TransitionComponent={Zoom} title={theButton.tooltip || ''} key={[index, key].filter(el => !!el).join('_')} classes={{ tooltip: classes.tooltipRoot }}>
                <MUIButton fullWidth={false} variant='contained' {...actionProps} color='primary' disabled={readonly || !(!theButton.readonly)} 
                  style={getButtonStyle(theButton, theme)} className={[classes.button, readonly || !(!theButton.readonly) ? classes.disabled : null, className].filter(el => !!el)} >{theButton.icon ? (
                    <Icon className={theButton.label ? classes.icon : ''}>{theButton.icon}</Icon>
                  ) : null}{theButton.label || null}</MUIButton>
              </Tooltip>
            )
          }

          return getButton(button)
        })}
      </div>
    )
  }
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Buttons)))
