import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  required: {
    color: theme.palette.text.required,
    marginLeft: 2
  }
})

const LabelWithAsterisk = ({ classes, children, ...props }) => {
  if (!children) return null
  
  const label = children.toString()

  const cleanLabel = label ? label.replace('*', '') : ''
  
  const isRequired = label ? label.includes('*') : false

  return (
    <React.Fragment>
      {cleanLabel}
      {isRequired && <span className={classes.required}>*</span>}
    </React.Fragment>
  )
}

LabelWithAsterisk.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default withStyles(styles)(LabelWithAsterisk)