import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'

import {} from '../../actions'

const styles = theme => ({
  inputFile: {
    display: 'flex'
  },
  inputFileLabel: {
    display: 'flex',
    margin: '0 .5rem 1rem',
    padding: '6px 16px',
    flexGrow: 0,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 3,
    backgroundColor: theme.palette.grey[100],
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  inputFileInput: {
    position: 'absolute',
    top: -500,
    left: -500
  },
  button: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    transition: 'opacity .3s ease-in-out'
  },
  label: {
    marginLeft: '.5rem'
  },
  disabled: {
    opacity: '.5'
  },
})

class Import extends React.Component {
  state = {
    file: null
  }

  handleFileChange = (event) => {
    return this.setState({
      file: event.target.files[0] ? event.target.files[0].name : null
    })
  }

  render() {
    const { classes, action, onClick, theme } = this.props

    const { icon, label } = action

    const { file } = this.state

    const getButtonStyle = (button, theme) => {
      const buttonType = button.buttonType || 'default'  

      return {
        ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
        ...(button.style || {}),
        ...(button.styles || {})
      }
    }

    return (
      <div className={classes.inputFile}>
        <label className={classes.inputFileLabel}>
          {file || action.fileLabel}
          <input className={classes.inputFileInput} onChange={this.handleFileChange} type='file' name='file' id='file' />
        </label>
        <Button variant='contained' disabled={!file} className={classes.button + (!file ? ' ' + classes.disabled : '')} style={getButtonStyle(action, theme)} onClick={() => onClick(action)}>
          {icon ? (
            <Icon>{icon}</Icon>
          ) : null}
          <span className={classes.label}>{label}</span>
        </Button>
      </div>
    )
  }
}

Import.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Import)))
