import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import MUITableRow from '@material-ui/core/TableRow'
import TableRow from './TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableActions from './TableActions'
import _ from 'lodash'
import TableFilter from './TableFilter'
import escapeStringRegexp from 'escape-string-regexp'
import { getPaginationConfig } from '../../utils/pagination'

import {
  appActions,
  listPageActions
} from '../../actions'

const styles = theme => ({
  title: {
    padding: '0 1rem'
  },
  tableCellTitle: {
    fontSize: '1rem',
    maxWidth: 200,
    width: 200,
    whiteSpace: 'nowrap'
  },
  tableCellAuto: {
    maxWidth: 'none',
    width: 'auto'
  },
  tableCell: {
    fontWeight: 700
  },
  tableCellCheckbox: {
    maxWidth: 70,
    width: 70,
    position: 'relative'
  },
  tableHead: {
    backgroundColor: theme.palette.background.title
  },
  pagination: {
    paddingRight: '0 !important',
    minWidth: 400,
  },
  paginationToolbar: {
    paddingLeft: 0
  },
  paginationSelect: {
    minWidth: 30
  }
})

class TableComponent extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: null,
    filters: {},
    selectedElements: {},
    selectAllPageElements: false
  }

  desc = (a, b, orderBy) => {
    const tmpA = a[orderBy + '_sort'] || a[orderBy]

    const tmpB = b[orderBy + '_sort'] || b[orderBy]

    let newParam = (typeof tmpA === 'string' ? tmpA.toUpperCase() : tmpA) || ''

    let oldParam = (typeof tmpB === 'string' ? tmpB.toUpperCase() : tmpB) || ''

    if (oldParam < newParam) {
      return -1
    }

    if (oldParam > newParam) {
      return 1
    }

    return 0
  }

  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy)
  }

  stableSort = (array, sortingMethod) => {
    const stabilizedThis = array.map((element, index) => [element, index])

    stabilizedThis.sort((a, b) => {
      const order = sortingMethod(a[0], b[0])

      if (order !== 0) return order

      return a[1] - b[1]
    })

    return stabilizedThis.map(el => el[0])
  }

  handleChangePage = (event, page) => {
    this.setState({
      page,
      selectedElements: {},
      selectAllPageElements: false
    })
  }

  handleChangeRowsPerPage = event => {
    let rowsPerPage = event.target.value

    const { tableBody } = this.props

    if (rowsPerPage === 'All') {
      rowsPerPage = tableBody.length
    }

    this.props.onStartLoading()

    setTimeout((obj) => {
      obj.setState({
        page: 0,
        rowsPerPage: parseInt(rowsPerPage, 10),
        selectedElements: {},
        selectAllPageElements: false
      })
    }, 100, this)
  }

  createSortHandler = (field) => {
    const { order, orderBy } = this.state

    let newOrder = (field === orderBy) ? (order === 'asc') ? 'desc' : 'asc' : order

    this.setState({
      page: 0,
      orderBy: field,
      order: newOrder
    }, () => {
      this.handleChangePage(null, 0)
    })
  }

  onFilterChange = (element, value) => {
    const {
      pageType
    } = this.props

    const { filters } = this.state

    if (value === null) {
      if (filters[element.field]) {
        delete filters[element.field]
      }

      return this.setState({
        page: 0,
        filters
      })
    }

    if (value || value === 0 || value === false || value === '') {
      filters[element.field] = {
        value: _.isObject(value) ? value.value : value,
        type: element.filter.type
      }
    }

    return this.setState({
      page: 0,
      filters
    }, () => {
      this.props.onUpdatePagination(this.state, pageType)
    })
  }

  stableFilter = (data) => {
    const { filters = {} } = this.state

    let newData = _.cloneDeep(data)

    Object.entries(filters).forEach(filterElement => {
      const filter = filterElement[0]

      const content = filterElement[1]

      return newData = newData.filter(element => {
        switch (content.type) {
          case 'select':
            if (element[filter + '_array']) {
              return element[filter + '_array'].indexOf(content.value) > -1
            }

            return element[filter] === content.value || (typeof element[filter] === 'string' && typeof content.value === 'string' && (element[filter] || '').toLowerCase() === (content.value || '').toLowerCase())
          default:
            const regex = RegExp('(' + escapeStringRegexp(content.value) + ')', 'ig')

            const filterFinded = regex.test(element[filter])

            if (filterFinded && typeof element[filter] === 'string') {
              element[filter] = element[filter] ? element[filter].replace(regex, '<strong>$1</strong>') : ''
            }

            return filterFinded
        }
      })
    })

    return newData
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...(getPaginationConfig(this.props.paginationConfig))
    }, () => {
      this.setState({
        mounted: true
      })
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      appConfig,
      onSelectElement,
      setFilteredItems,
      onUpdatePagination = {},
      pageType
    } = this.props

    const { appConfig: prevAppConfig, setFilteredItems: prevSetFilteredItems } = prevProps

    if (appConfig.openLoading === prevAppConfig.openLoading) {
      if (appConfig.openLoading) {
        this.props.onStopLoading()
      }
    }

    if (!prevSetFilteredItems && setFilteredItems) {
      const { tableBody } = this.props

      const filteredData = this.stableFilter(tableBody)

      this.props.onSetFilteredItems(filteredData)
    }

    const { selectedElements } = this.state

    onSelectElement(selectedElements)

    if (!_.isEqual(prevState, this.state)) {
      onUpdatePagination(this.state, pageType)
    }
  }

  getPageData = (filteredData) => {
    const {
      page,
      rowsPerPage,
      order,
      orderBy
    } = this.state

    const from = page * rowsPerPage

    const to = from + rowsPerPage

    return this.stableSort(filteredData, this.getSorting(order, orderBy)).slice(from, to)
  }

  selectElement = (event, element) => {
    const { checked } = event.target

    const { selectedElements } = this.state

    if (checked) {
      if (!selectedElements[element.id]) {
        selectedElements[element.id] = true
      }
    } else {
      if (selectedElements[element.id]) {
        selectedElements[element.id] = false
      }
    }

    return this.setState({
      selectedElements,
      selectAllPageElements: false
    })
  }

  selectAllPageElements = () => {
    const { tableBody } = this.props

    const { selectAllPageElements } = this.state

    if (selectAllPageElements) {
      return this.setState({
        selectAllPageElements: false,
        selectedElements: {}
      })
    }

    const filteredData = this.stableFilter(tableBody)

    const data = this.getPageData(filteredData)

    const selectedElements = {}

    data.forEach(element => {
      selectedElements[element.id] = true
    })

    this.props.onStartLoading()

    this.setState({
      selectAllPageElements: true,
      selectedElements
    }, () => {
      this.props.onStopLoading()
    })
  }

  render() {
    const {
      classes,
      tableHead = [],
      tableHeadActions,
      labels,
      tableBody,
      tableActions = tableHead.length === 1 ? [{}] : null,
      onClickAction,
      activeBulk = true,
      requestPagination = {}
    } = this.props

    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      selectAllPageElements,
      selectedElements,
      filters,
      mounted
    } = this.state

    if (!mounted) {
      return ''
    }

    const filteredData = this.stableFilter(tableBody)

    const data = this.getPageData(filteredData)

    let footerSize = tableHead.length || 0

    if (tableActions && tableActions.length) {
      footerSize++
    }

    if (activeBulk) {
      footerSize++
    }

    const paginationLoadingSize = footerSize > 2 ? footerSize - 2 : 1

    const paginationSize = footerSize > 2 ? 2 : 1

    const completed = (100 / requestPagination.pageCount) * requestPagination.page

    const haveFilters = (tableHead || []).filter(element => {
      return !!element.filter
    }).length > 0

    return (
      <Table className={classes.table} aria-labelledby='tableTitle'>
        <TableHead>
          <MUITableRow className={classes.tableHead}>
            {activeBulk ? (
              <TableCell className={classes.tableCellTitle + ' ' + classes.tableCellCheckbox} padding='checkbox'>
              </TableCell>
            ) : null}
            {tableHead.map((element, index) => {
              return (
                <TableCell sortDirection={index === 0 ? 'asc' : false} key={index} className={classes.tableCellTitle + (!element.filter ? ' ' + classes.tableCellAuto : '')} padding='checkbox'>
                  <TableSortLabel
                    active={orderBy === element.field}
                    direction={order}
                    onClick={() => this.createSortHandler(element.field)} >
                    {element.label}
                  </TableSortLabel>
                </TableCell>
              )
            })}
            {(tableActions && tableActions.length) || (tableHeadActions && tableHeadActions.length) ? (
              <TableCell className={classes.tableCellTitle} padding='checkbox'>
                {(tableHeadActions && tableHeadActions.length) ? (
                  <TableActions actions={tableHeadActions} item={{}} itemId={null} onClickAction={onClickAction} />
                ) : null}
              </TableCell>
            ) : null}
          </MUITableRow>
          {haveFilters ? (
            <MUITableRow>
              {activeBulk ? (
                <TableCell className={classes.tableCellTitle + ' ' + classes.tableCellCheckbox} padding='checkbox'>
                  <Checkbox checked={selectAllPageElements} onChange={this.selectAllPageElements} />
                </TableCell>
              ) : null}
              {tableHead.map((element, index) => {
                if (!element.filter) {
                  return (
                    <TableCell key={index} padding='checkbox'>
                    </TableCell>
                  )
                }
                return (
                  <TableCell key={index} padding='checkbox'>
                    <TableFilter element={element} filters={filters} onChange={this.onFilterChange} />
                  </TableCell>
                )
              })}
              {tableActions && tableActions.length ? (
                <TableCell className={classes.tableCellTitle} padding='checkbox'>
                </TableCell>
              ) : null}
            </MUITableRow>
          ) : null}
        </TableHead>
        <TableBody>
          {data.length === 0 ? (
            <MUITableRow hover role='checkbox' tabIndex={-1} selected={false} aria-checked='false'>
              <TableCell colSpan={tableHead.length + (activeBulk ? 2 : 1)} className={classes.tableCellCheckbox} padding='checkbox'>
                {(labels || {}).noResults}
              </TableCell>
            </MUITableRow>
          ) : data.map(element => {
            const isChecked = selectedElements[element.id] || false

            return (
              <TableRow key={element.id} {...{
                element,
                onChange: (event) => {
                  return this.selectElement(event, element)
                },
                activeBulk,
                isChecked,
                rowClasses: classes,
                tableHead,
                tableActions,
                onClickAction
              }} />
            )
          })}
        </TableBody>
        <TableFooter>
          <MUITableRow>
            <TableCell colSpan={paginationLoadingSize}>
              {completed === 100 || !requestPagination.page ? null : (
                <LinearProgress variant='determinate' value={completed} />
              )}
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 150]}
              count={filteredData.length}
              rowsPerPage={parseInt(rowsPerPage)}
              page={page}
              colSpan={paginationSize}
              classes={{
                root: classes.pagination,
                toolbar: classes.paginationToolbar,
                select: classes.paginationSelect
              }}
              labelRowsPerPage='Elementi per pagina'
              SelectProps={{
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </MUITableRow>
        </TableFooter>
      </Table>
    )
  }
}

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.appReducer,
  ...state.listPageReducer,
  ...state.paginationConfigReducer
})

const mapDispatchToProps = dispatch => ({
  onUpdatePagination: (paginationConfig, pageType) => {
    dispatch(listPageActions.updatePagination(paginationConfig, pageType))
  },
  onSetFilteredItems: (data) => {
    dispatch(appActions.setFilteredItems(data))
  },
  onStartLoading: () => {
    dispatch(appActions.startLoading())
  },
  onStopLoading: () => {
    dispatch(appActions.stopLoading())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableComponent)))
