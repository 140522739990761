import store from '../stores'
import _ from 'lodash'

import {
  messageActions
} from '../actions'

const initialState = {
  formContent: null,
  exitForm: false,
  lastFieldUpdated: {},
  newId: null,
  goTo: null,
  redirect: null,
  promptMessage: true
}

const calcValue = (config, fields, baseValue) => {
  let tmpValue = 0

  let tmpBaseValue = 0

  let tmpAmount = 0

  switch (config.type) {
    case 'molt':
      tmpValue = parseFloat(baseValue)

      if (!config.global) {
        config.withFields.forEach(configFieldName => {
          if (!_.isArray(fields)) {
            return Object.entries(fields).forEach(([fieldName, value]) => {
              if (configFieldName === fieldName) {
                tmpAmount += parseFloat(value)

                return tmpValue *= parseFloat(value)
              }
            })
          }
        })
      }

      tmpBaseValue = tmpAmount ? tmpValue / tmpAmount : 0

      break
    case 'summ':
      if (config.global) {
        config.fields.forEach(configFieldName => {
          if (_.isArray(fields)) {
            return fields.forEach(field => {
              if (configFieldName === field.name) {
                return tmpValue += parseFloat(field.value) || 0
              }

              if (field.values) {
                const currentField = field.fields.find(nestedField => {
                  return nestedField.name === configFieldName
                })

                if (currentField) {
                  field.values.forEach(nestedValue => {
                    if (nestedValue[configFieldName]) {
                      let currentValue = parseFloat(nestedValue[configFieldName])

                      if (config.withVat && nestedValue[currentField.currentVat]) {
                        currentValue = currentValue * ((nestedValue[currentField.currentVat] / 100) + 1)
                      }

                      return tmpValue += currentValue
                    }
                  })
                }
              }
            })
          }
        })
      }

      break
    default:
  }

  if (config.toFixed) {
    return {
      amount: tmpAmount,
      value: tmpValue.toFixed(config.toFixed),
      baseValue: tmpBaseValue.toFixed(config.toFixed),
    }
  }

  return {
    amount: tmpAmount,
    value: tmpValue,
    baseValue: tmpBaseValue
  }
}

// Vars
let newState = null
let currentField = null

const formReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'form_action_commit_changes':
      newState = _.cloneDeep(state)

      if (action.fromProp) {
        newState.lastUpdate = (new Date()).getTime()
      }

      return newState
    case 'form_action_remove_from_list':
      newState = _.cloneDeep(state)

      newState.formContent.rightList[action.listIndex].body.splice(action.index, 1)

      return newState
    case 'form_action_preppend_ro_values_from_ajax_get':
      newState = _.cloneDeep(state)

      newState.formContent.fields.forEach(field => {
        if (field.name === action.fieldName) {
          if (action.replaceValues) {
            return field.values ? field.values = [] : field.value = null
          }
        }
      })

      return newState
    case 'form_action_preppend_ro_values_from_ajax':
      newState = _.cloneDeep(state)

      newState.formContent.fields.forEach(field => {
        if (field.name === action.config.fieldName) {
          if (action.config.replaceValues) {
            return field.values ? field.values = action.config.data : field.value = action.config.data
          }

          field.values = field.values.filter(filteredValue => {
            return !filteredValue.readonly
          })

          return field.values.unshift(...action.config.data)
        }
      })

      return newState
    case 'form_action_form_calc_value':
      newState = _.cloneDeep(state)

      if (newState.formContent) {
        newState.formContent.fields.forEach((field, index) => {
          if (field.fields) {
            return field.fields.forEach(dynamicField => {
              if (dynamicField.calcValue && dynamicField.calcValue.global === action.global) {
                field.values.forEach((fieldValue, valueIndex) => {
                  if (newState.lastFieldUpdated.fieldIndex !== index || newState.lastFieldUpdated.fieldName !== dynamicField.name || valueIndex !== newState.lastFieldUpdated.dynamicIndex) {
                    if ((!fieldValue[dynamicField.name + '_base_value'] || parseFloat(fieldValue[dynamicField.name + '_base_value']) === 0) && !Number.isNaN(parseFloat(fieldValue[dynamicField.name]))) {
                      fieldValue[dynamicField.name + '_base_value'] = fieldValue[dynamicField.name]
                    }

                    const calculatedValue = calcValue(dynamicField.calcValue, fieldValue, fieldValue[dynamicField.name + '_base_value'])

                    fieldValue[dynamicField.name] = calculatedValue.value

                    fieldValue[dynamicField.name + '_amount'] = calculatedValue.amount
                  }
                })
              }
            })
          }

          if (field.calcValue && field.calcValue.global === action.global) {
            if (newState.lastFieldUpdated.fieldIndex !== index || newState.lastFieldUpdated.fieldName !== field.name) {
              if (!field.baseValue) {
                field.baseValue = field.value
              }

              const calculatedValue = calcValue(field.calcValue, newState.formContent.fields, field.baseValue)

              return field.value = calculatedValue.value
            }
          }
        })
      }

      newState.lastFieldUpdated = {}

      return newState
    case 'form_action_form_update_field_props':
      newState = _.cloneDeep(state)

      if (action.config.dynamicField && action.config.fieldProp && Number.isInteger(action.config.fieldIndex)) {
        if (newState.formContent.fields[action.config.fieldIndex].fields) {
          const dynamicIndex = newState.formContent.fields[action.config.fieldIndex].fields.findIndex(item => {
            return item.name === action.config.dynamicField
          })

          if (dynamicIndex > -1) {
            newState.formContent.fields[action.config.fieldIndex].fields[dynamicIndex][action.config.fieldProp] = action.value
          }
        }
      } else {
        newState.formContent.fields.forEach(field => {
          if (action.config.fieldProp && Number.isInteger(action.config.index) && Number.isInteger(action.config.fieldIndex)) {
            if (field.fields && field.fields[action.config.fieldIndex] && !action.config.forParent) {
              return field.fields[action.config.fieldIndex][action.config.fieldProp] = action.value
            }
          }

          if (field.name === action.config.fieldName) {
            if (Number.isInteger(action.config.index) && !action.config.forParent) {
              return field.values[action.config.index][action.config.fieldProp] = action.value
            }

            if (action.config.fieldChildName && action.config.fieldChildrenName) {
              const childrenValue = field[action.config.fieldChildrenName]

              if (childrenValue[action.config.childFieldIndex]) {
                return childrenValue[action.config.childFieldIndex][action.config.fieldProp] = action.value
              }

              return childrenValue.forEach(button => {
                return button.type === 'multibutton'
                  ? button.buttons.forEach(subButton =>
                    (subButton.name === action.config.fieldChildName)
                      ? (subButton[action.config.fieldProp] = action.value, true)
                      : false)
                  : false
              })
            }
            
            return field[action.config.fieldProp] = action.config.fieldProp !== 'readonly' ? action.value : !!action.value
          }
        })
      }

      return newState
    case 'form_action_form_save_post':
      newState = {
        ..._.cloneDeep(state),
        saving: true
      }

      return newState
    case 'form_action_form_save':
      newState = {
        ..._.cloneDeep(state),
        exitForm: action.data.status === 'success',
        newId: action.data.data ? action.data.data.id || null : null,
        goTo: action.data.data.goTo || null,
        saving: false,
      }

      newState.formContent.temporarySave = action.temporarySave

      let errorMessage = {
        message: action.data.message,
        type: action.data.status === 'success' ? 'success' : 'error',
        autoHide: true
      }

      if (action.data.status === 'error') {
        Object.entries(action.data.data).forEach(([field, errors]) => {
          const error = errors.find(el => el) || null

          if (error) {
            newState.formContent.fields.forEach(element => {
              if (!action.data.data[element.name]) {
                element.error = null
              }

              if (element.name === field) {
                if (typeof error === 'string') {
                  element.error = error
                } else {
                  element.error = errors

                  errors.forEach((innerErrors, innerIndex) => {
                    Object.entries(element.values[innerIndex]).forEach(([fieldName]) => {
                      if (_.isObject(element.values[innerIndex][fieldName])) {
                        return element.values[innerIndex][fieldName].error = null
                      }
                    })

                    if (innerErrors) {
                      Object.entries(innerErrors).forEach(([innerError, innerValue]) => {
                        if (element.values && element.values[innerIndex]) {
                          if (_.isObject(element.values[innerIndex][innerError])) {
                            element.values[innerIndex][innerError].error = innerValue
                          }
                        }
                      })
                    }
                  })
                }
              }
            })
          }
        })
      }

      if (!action.temporarySave) {
        setTimeout(() => {
          return store.dispatch(messageActions.showMessage(errorMessage))
        }, 200)
      }

      return newState
    case 'form_action_form_remove_value':
      newState = {
        ..._.cloneDeep(state),
      }

      currentField = !_.isInteger(action.dynamicIndex) ? newState.formContent.fields : newState.formContent.fields[action.fieldIndex].values

      currentField.splice(_.isInteger(action.dynamicIndex) ? action.dynamicIndex : action.dynamicIndex, 1)

      return newState
    case 'form_action_form_replace_values':
      newState = {
        ..._.cloneDeep(state),
      }

      newState.formContent.fields.forEach(tmpField => {
        if (typeof action.data[tmpField.name] !== 'undefined') {
          if (action.config.valueChild) {
            if (!tmpField.value) {
              tmpField.value = {}
            }

            tmpField.value[action.config.valueChild] = action.data[tmpField.name]
          } else {
            tmpField.value = action.data[tmpField.name]

            if (tmpField.type === 'select' && tmpField.isMulti) {
              tmpField.values = action.data[tmpField.name]
            }
          }
        }
      })

      return newState
    case 'form_action_update_options':
      newState = {
        ..._.cloneDeep(state),
      }

      newState.formContent.fields.forEach(tmpField => {
        if (tmpField.name === action.fieldName) {
          return tmpField.options = _.isArray(action.value) ? action.value : [action.value]
        }
      })

      return newState
    case 'form_action_form_add_value':
      newState = {
        ..._.cloneDeep(state)
      }

      newState.formContent.fields[action.fieldIndex].error = null

      currentField = newState.formContent.fields[action.fieldIndex]

      if (_.isInteger(action.dynamicIndex)) {
        if (!currentField.values[action.dynamicIndex]) {
          currentField.values[action.dynamicIndex] = {}
        }

        currentField = currentField.values[action.dynamicIndex]
      }

      if (_.isArray(action.value)) {
        const isEmpty = !action.value.length

        if (_.isInteger(action.dynamicIndex)) {
          currentField[action.fieldName] = isEmpty ? [] : action.value
        } else {
          currentField.values = isEmpty ? [] : action.value

          if (!isEmpty) {
            currentField.values.forEach(value => {
              if (!value.id) {
                value.id = _.uniqueId('field_id')
              }
            })
          }
        }
      } else {
        if (_.isInteger(action.dynamicIndex)) {
          if (Object.prototype.hasOwnProperty.call(currentField, action.fieldName)) {
            currentField[action.fieldName] = action.value

            const tmpAmount = currentField[action.fieldName + '_amount']

            if (tmpAmount) {
              currentField[action.fieldName + '_base_value'] = action.value / tmpAmount
            }
          } else {
            newState.formContent.fields[action.fieldIndex].values[action.dynamicIndex] = action.value
          }
        } else {
          currentField.value = action.append ? [currentField.value, action.value].filter(item => !(!item)).join('\n') : action.value
        }
      }

      newState.lastFieldUpdated = {
        fieldIndex: action.fieldIndex,
        dynamicIndex: action.dynamicIndex,
        fieldName: action.fieldName
      }

      return newState
    case 'form_action_form':
      newState = {
        ..._.cloneDeep(state),
        exitForm: false,
        redirect: null,
        formContent: action.data ? action.data.data : null
      }

      if (newState.formContent && newState.formContent.goTo) {
        newState = {
          ...initialState,
          redirect: newState.formContent.goTo
        }
      }

      if (newState.formContent && newState.formContent.fields) {
        newState.formContent.fields.forEach((field) => {
          if (field.values) {
            field.values.forEach((fieldValue => {
              return fieldValue.id = _.uniqueId('field_id')
            }))
          }
        })
      } else {
        newState.formContent = null
      }

      return newState
    default:
      return state
  }
}

export default formReducer
