import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Tooltip } from '@material-ui/core'
import Icon from '../../../components/Icon'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    padding: '0 1rem',
    marginTop: '1rem',
    marginBottom: '-.5rem'
  },
  tabsAction: {
    gap: '5px',
    padding: '0 .5rem',
    position: 'fixed',
    right: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.tabs,
    left: theme.appVars.sidebarWidth.main,
    paddingLeft: 34,
    paddingTop: 19,
    top: theme.appVars.breadcrumbsHeight + theme.appVars.navBarHeight,
    transition: 'left .3s ease-in-out',
  },
  tabsActionSidebarIsOpen: {
    left: theme.appVars.sidebarWidth.open + 17,
  },
  tabAction: {
    minWidth: 0,
    marginTop: '.5rem',
    '&:hover': {
      backgroundColor: theme.palette.background.activeTabs,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.text.sidebar + '!important'
    }
  },
  tabActionLabel: {
    padding: '0 12px'
  },
  tabActionActive: {
    fontWeight: 700,
    color: theme.palette.text.sidebar + '!important',
    backgroundColor: theme.palette.background.activeTabs,
    borderRadius: '5px 5px 0 0',
    '&:hover': {
      backgroundColor: theme.palette.background.activeTabsHover,
    }
  },
  badge: {
    color: '#fff',
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginLeft: 8
  },
  indicator: {
    display: 'none'
  }
})

class TabActons extends React.Component {
  render() {
    const { actions, classes, appConfig, sidebarIsOpen } = this.props    

    return (
      <Tabs
        value={actions.findIndex(action => {
          return action.active
        })}
        variant='scrollable'
        scrollButtons='off'
        indicatorColor='primary'
        textColor='primary'
        classes={{
          root: classes.root,
          flexContainer: classes.tabsAction + (sidebarIsOpen ? ' ' + classes.tabsActionSidebarIsOpen : ''),
          indicator: classes.indicator
        }} >
        {actions.map((action, index) => {
          return (
            <Tooltip key={index} title={action.tooltip || ''}>
              <Tab
                classes={{
                  root: classes.tabAction + (action.active ? ' ' + classes.tabActionActive : ''),
                  labelContainer: classes.tabActionLabel
                }}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {action.label}
                    {action.accessoryViews && action.accessoryViews.map((badge, badgeIndex) => {
                      if (!badge.needsAttention) return null

                      const badgeContent = badge.type === 'numeric' ? badge.value : (badge.type === 'icon' ? <Icon style={badge.iconStyle} children={badge.icon} /> : null)

                      if (!badgeContent) return null

                      return (
                        <Tooltip key={badgeIndex} title={badge.tooltip || ''}>
                          <span className={badge.needsAttention ? classes.badge : ''}
                            style={badge.badgeStyle}>
                            {badgeContent}
                          </span>
                        </Tooltip>
                      )
                    })}
                  </div>
                }
                key={index}
                component={NavLink}
                to={appConfig.appPrefix + action.action} />
            </Tooltip>
          )
        })}
      </Tabs>
    )
  }
}

TabActons.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TabActons)))
