import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Label from './Label'
import Empty from './Empty'
import String from './String'
import Radio from './Radio'
import Date from './Date'
import Multiline from './Multiline'
import Title from './Title'
import Text from './Text'
import Image from './Image'
import Checkbox from './Checkbox'
import Boolean from './Boolean'
import Select from './Select'
import ComboText from './ComboText'
import _ from 'lodash'

import {
  blockActions
} from '../../actions'

const styles = theme => ({
  root: {
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.default
  }
})

const cells = {
  label: Label,
  string: String,
  radio: Radio,
  date: Date,
  multiline: Multiline,
  title: Title,
  text: Text,
  image: Image,
  checkbox: Checkbox,
  boolean: Boolean,
  select: Select,
  combotext: ComboText
}

class BaseField extends React.Component {
  componentDidMount = () => {
    const { cell, fieldPath } = this.props

    this.props.onAddToPathMapping(cell.name, fieldPath)
  }

  render() {
    const { cell, isActive = true, fieldPath, onChange, rowIsActive = true, readonly, fieldPathMapping, blockData } = this.props

    const { blocks } = blockData

    const { type } = cell

    const TmpCell = cells[type] || Empty

    let cellIsActive = isActive

    if (cellIsActive) {
      cellIsActive = cell.rowControl || rowIsActive
    }

    const activeProps = {
      isActive: cellIsActive
    }

    if (type === 'multiline') {
      activeProps.isActive = isActive

      activeProps.rowIsActive = rowIsActive
    }

    let celReadOnly = _.cloneDeep(cell.readonly)

    if (_.isObject(celReadOnly) && !_.isEmpty(fieldPathMapping)) {
      Object.entries(celReadOnly).forEach(([type, config]) => {
        switch (type) {
          case 'ifFieldValue':
            const currentField = _.get(blocks, fieldPathMapping[config.field]) || {}

            const fieldValue = currentField.value

            cell.readonly = ('' + fieldValue) === ('' + config.value)

            break
          default:

        }
      })
    }

    return (
      <TmpCell cell={cell} {...activeProps} fieldPath={fieldPath} onChange={onChange} {...{readonly}} />
    )
  }
}

BaseField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.blockReducer })

const mapDispatchToProps = dispatch => ({
  onAddToPathMapping: (fieldName, fieldPath) => {
    dispatch(blockActions.addToPathMapping(fieldName, fieldPath))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BaseField)))
