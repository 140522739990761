import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { validators } from './Validators'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { stripeHtml } from '../../utils'
import LabelWithAsterisk from './LabelWithAsterisk'

import { } from '../../actions'

const styles = theme => ({
  root: {
    width: '100%',
    margin: '16px 0 8px'
  },
  rootNoLabel: {
    marginTop: 0
  },
  label: {
    fontSize: '0.9285714285714286rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '.25rem'
  },
  snippet: {
    fontSize: '.75rem',
  },
  editorReadOnly: {
    padding: '0 .5rem',
    border: '1px solid ' + theme.palette.border.table,
    backgroundColor: theme.palette.background.readOnly,
    '& .public-DraftEditor-content': {
      minHeight: 200,
      padding: '1rem .5rem'
    }
  },
  toolbar: {
    border: '1px solid ' + theme.palette.border.table + ' !important',
  },
  editorToolbar: {
    backgroundColor: theme.palette.notefield.editorToolbar,
    marginBottom: 0,
    border: '1px solid ' + theme.palette.border.table,
    '& .public-DraftEditor-content': {
      minHeight: 200,
      padding: '0 .5rem'
    },
    '& .rdw-option-wrapper': {
      backgroundColor: theme.palette.notefield.wrappersBackground,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.notefield.optionHover
      },
      '&.rdw-option-active': {
        backgroundColor: theme.palette.notefield.optionActive,
      },
      '&.rdw-option-disabled': {
        opacity: '0.6',
        cursor: 'not-allowed'
      },
    },
    '& .rdw-dropdown-wrapper': {
      backgroundColor: theme.palette.notefield.wrappersBackground,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.notefield.optionHover
      },
    },
    '& .rdw-link-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-colorpicker-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-emoji-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-link-modal-btn': {
      backgroundColor: theme.palette.notefield.btn,
      color: theme.palette.notefield.textWhite,
      fontWeight: 700,
      border: 'none',
      boxShadow: 'none',
      '&:disabled': {
        backgroundColor: theme.palette.notefield.btnDisabled,
        cursor: 'not-allowed'
      },
      '&:not(:disabled):hover': {
        backgroundColor: theme.palette.notefield.btnHover,
      },
    },
    '& .rdw-dropdown-optionwrapper': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.dropdownBackground,
      '&:hover': {
        boxShadow: 'none'
      },
    },
    '& .rdw-dropdownoption-highlighted': {
      backgroundColor: theme.palette.notefield.dropdownHighlighted
    },
    '& .rdw-dropdown-selectedtext': {
      color: theme.palette.notefield.dropdownTextSelected
    },
    '& .rdw-link-modal-input': {
      border: theme.palette.notefield.border,
      backgroundColor: theme.palette.notefield.linkModalInput,
      color: theme.palette.notefield.textReverse,
    },
    '& .rdw-dropdownoption-active ': {
      backgroundColor: theme.palette.notefield.dropdownOptionActive
    }
  }
})

class RichText extends React.Component {
  state = {
    stringValue: '',
    editorState: null,
    mounted: false
  }

  componentDidMount = () => {
    const { value } = this.props.field

    this.setState({
      stringValue: value || '',
      editorState: this.getStateFromHtml(value || ''),
      mounted: true
    })

    return validators.mount(this)
  }

  getStateFromHtml = (html) => {
    return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks))
  }

  componentDidUpdate(prevProps) {
    // TODO gestione updateRelated
    return validators.update(this, prevProps)
  }

  render() {
    const { classes, field, onChange } = this.props

    const { mounted, editorState } = this.state

    if (!mounted) {
      return ''
    }

    return (
      <div className={classes.root + (!field.label ? ' ' + classes.rootNoLabel : '')}>
        {field.label ? (
          <div className={classes.label}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></div>
        ) : null}
        <Editor
          editorState={editorState}
          toolbarClassName={classes.editorToolbar}
          wrapperClassName='RichText-wrapper'
          editorClassName={field.readonly ? classes.editorReadOnly : classes.editorToolbar}
          readOnly={!(!field.readonly)}
          onEditorStateChange={(editorState) => {
            const stringValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))

            this.setState({
              editorState,
              stringValue
            })

            onChange({ type: 'richtext', directChange: true }, field, stripeHtml(stringValue) ? stringValue : '')
          }}
          toolbar={{
            options: ['inline', 'colorPicker', 'list', 'textAlign', 'link', 'history'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered', 'indent', 'outdent'],
            },
            textAlign: {
              inDropdown: false,
              options: ['left', 'center', 'right', 'justify'],
            },
          }} />
        {field.snippet ? (
          <div className={classes.snippet}>{field.snippet}</div>
        ) : null}
      </div>
    )
  }
}

RichText.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RichText)))
