import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import String from './String'
import Text from './Text'
import Checkbox from './Checkbox'
import SelectField from './Select'
import Address from './Address'
import Dynamic from './Dynamic'
import Modal from './Modal'
import Contact from './Contact'
import Date from './Date'
import Print from './Print'
import Preview from './Preview'
import Label from './Label'
import Title from './Title'
import ComboText from './ComboText'
import ButtonComponent from './Button'
import Buttons from './Buttons'
import ActionList from './ActionList'
import RichText from './RichText'
import File from './File'
import InlineText from './InlineText'
import Radio from './Radio'
import Divider from './Divider'
import Audiometric from './Audiometric'
import TableCheckbox from './TableCheckbox'
import Tags from './Tags'
import Info from './Info'
import Steps from './Steps'
import Status from './Status'
import CollapseEnd from './CollapseEnd'

import {} from '../../actions'

const styles = theme => ({
  root: {
    padding: '0 1rem',
    display: 'flex',
    position: 'relative'
  },
  componentRoot: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: '100%'
  },
  specials: {
    display: 'flex',
    position: 'absolute',
    right: '.5rem',
    top: '.01rem',
    flexDirection: 'row',
    gap: '10px'
  },
  special: {
    minWidth: 0,
  },
  inGroup: {
    paddingTop: '0 !important',
  },
  isFirst: {
    marginBottom: '1rem'
  },
  hidden: {
    display: 'none',
    visibility: 'hidden'
  },
  noShow: {
    display: 'none'
  },
  button: {
    padding: 9
  },
  divider: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
  dividerRight: {
    borderRight: '1px solid ' + theme.palette.grey[200]
  },
  dividerLeft: {
    borderLeft: '1px solid ' + theme.palette.grey[200]
  },
  dividerTop: {
    borderTop: '1px solid ' + theme.palette.grey[200]
  },
  dividerBottom: {
    borderBottom: '1px solid ' + theme.palette.grey[200]
  }
})

const fields = {
  string: String,
  text: Text,
  checkbox: Checkbox,
  select: SelectField,
  address: Address,
  contact: Contact,
  date: Date,
  print: Print,
  dynamic: Dynamic,
  modal: Modal,
  preview: Preview,
  label: Label,
  combotext: ComboText,
  title: Title,
  button: ButtonComponent,
  buttons: Buttons,
  actionList: ActionList,
  richtext: RichText,
  file: File,
  inlinetext: InlineText,
  radio: Radio,
  divider: Divider,
  audiometric: Audiometric,
  tablecheckbox: TableCheckbox,
  tags: Tags,
  info: Info,
  steps: Steps,
  status: Status,
  collapseEnd: CollapseEnd
}

class FormField extends React.Component {
  render() {
    const {
      classes,
      field,
      onChange,
      onSpecialClick,
      gridClass,
      fieldIndex,
      inGroup,
      onActionClick,
      readonly,
      formType,
      isFirst,
      theme
    } = this.props

    if (!fields[field.type]) {
      return ''
    }

    const TmpField = fields[field.type]

    const gridClasses = [gridClass || classes.root]

    if (inGroup) {
      gridClasses.push(classes.inGroup)
    }

    if (isFirst && inGroup && ['label', 'title'].indexOf(field.type) > -1) {
      gridClasses.push(classes.isFirst)
    }

    const dividerClasses = [classes.divider]

    const items = field.divider || []

    items.forEach(item => {
      dividerClasses.push(classes['divider' + item])
    })

    const rowStyles = {
      ...(field.rowStyles || {}),
      ...(field.rowStyles && field.rowStyles.isOdd ? { backgroundColor: theme.palette.background.colored } : {})
    }

    return (
      <Grid item xs={field.size || 12} className={gridClasses.join(' ') + (field.noShow ? ' ' + classes.noShow : '')} style={rowStyles}>
        {field.divider ? (
          <div className={dividerClasses.join(' ')}></div>
        ) : null}
        <div className={classes.componentRoot + (field.hidden ? ' ' + classes.hidden : '')}>
          <TmpField field={field} formType={formType} onChange={onChange} fieldIndex={fieldIndex} inGroup={inGroup} onActionClick={onActionClick} readonly={field.forceEditable ? false : readonly || field.readonly} />
          {field.specials && !readonly ? (
            <div className={classes.specials}>
              {field.specials.map((special, index) => (
                <Tooltip key={index} TransitionComponent={Zoom} title={special.tooltip || ''} classes={{ tooltip: classes.tooltipRoot }}>
                  <Button size='small' variant='contained' onClick={() => {
                    return onSpecialClick(special)
                  }} className={classes.special} key={index}>
                    {special.icon && <Icon>{special.icon}</Icon>}
                    <span className={classes.specialLabel}>{special.label}</span>
                  </Button>
                </Tooltip>
              ))}
            </div>
          ) : ''}
          {field.buttons && field.type !== 'buttons' ? (
            <div className={classes.buttonsRoot}>
              {field.buttons.map((button, index) => {
                return (
                  <Tooltip key={index} TransitionComponent={Zoom} title={button.tooltip || ''} classes={{ tooltip: classes.tooltipRoot }}>
                    <div>
                      {button.type === 'icon' ? (
                        <IconButton className={classes.button} onClick={() => {
                          return onChange({type: 'innerbutton'}, {
                            ...field,
                            ...button
                          }, field.value)
                        }}>
                          <Icon>{button.icon}</Icon>
                        </IconButton>
                      ) : (
                        <Button className={classes.button}>
                          {button.icon ? (
                            <Icon>{button.icon}</Icon>
                          ) : null}
                          {button.label || null}
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                )
              })}
            </div>
          ) : null}
        </div>
      </Grid>
    )
  }
}

FormField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FormField)))
