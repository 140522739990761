import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../Icon'
import Date from './Date'
import Checkbox from './Checkbox'
import moment from 'moment'
import _ from 'lodash'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  checkbox: {
    marginRight: '1rem',
    marginTop: -13
  },
  checkboxLabel: {
    fontSize: '0.9285714285714286rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: '-.15rem 0 .125rem'
  },
  checkboxButtonContainer: {
    display: 'flex',
  },
  checkboxRoot: {
    margin: '0 0 0 -8px'
  },
  switchRoot: {
    height: 34
  },
  buttonIcon: {
    marginRight: '.5rem'
  },
  dateContainer: {
    flexGrow: 1,
  },
  disabled: {
    opacity: '.5'
  },
  multiButtonContainer: {
    margin: '0 1rem',
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    display: 'flex'
  },
  multiButton: {
    flex: 1,
    margin: 0,
    borderRadius: 0
  },
  multiButtonPrimary: {
    backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printPdf.backgroundColor}!important`,
      opacity: 0.9
    }
  },
  multiButtonSecondary: {
    backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.buttonStyles.printForDrive.backgroundColor}!important`,
      opacity: 0.9
    }
  }
})

class Print extends React.Component {
  state = {}

  startPrint = () => {
    const { field, onChange, match } = this.props

    const { saveBeforeAction } = field

    const tmpValue = _.cloneDeep(field.value)

    const button = tmpValue.button.type === 'multibutton' ? tmpValue.button.buttons[this.state.clickedIndex] : tmpValue.button

    const params = button.value.params || {}

    this.setState({
      clickedIndex: 0
    }, () => {
      if (saveBeforeAction) {
        return onChange({ type: 'saveBeforeAction' }, {
          callback: this.props.onPrintPost,
          callbackProps: [button.value.url, {
            ...params,
            date: field.value.date.value
          }],
          params: match.params
        }, '')
      }

      return this.props.onPrintPost(button.value.url, {
        ...params,
        date: field.value.date.value
      })
    })
  }

  onButtonClick = (index) => {
    const { field, onChange, appConfig } = this.props

    const { dateFormat } = appConfig

    const tmpValue = _.cloneDeep(field.value)

    tmpValue.date.value = field.value.date.value || moment().format(dateFormat)

    tmpValue.checkbox.value = true

    this.setState({
      clicked: true,
      clickedIndex: index
    }, () => {
      onChange({ type: 'update' }, field, tmpValue)
    })
  }

  componentDidUpdate = (prevProps) => {
    const {
      clicked
    } = this.state

    const {
      date: prevDate = {},
      checkbox: prevCheckbox = {}
    } = prevProps.field.value

    const {
      date = {},
      checkbox = {}
    } = this.props.field.value

    const checked = (!prevCheckbox.value && checkbox.value) || checkbox.value

    const withDate = !(!((!prevDate.value && date.value) || date.value))

    if (checked && withDate && clicked) {
      return this.setState({
        clicked: false
      }, this.startPrint)
    }
  }

  render() {
    const { classes, field, onChange, readonly, theme } = this.props

    const { checkbox, button, date } = field.value

    if (!checkbox) {
      return ''
    }

    const getButton = (theButton, className, key) => {
      if (theButton.type === 'multibutton') {
        return (
          <div className={classes.multiButtonContainer}>
            {theButton.buttons.map((innerButton, index) => {
              const buttonClassName = `${classes.multiButton} ${index === 0 ? classes.multiButtonPrimary : classes.multiButtonSecondary}`

              return getButton(innerButton, buttonClassName, index)
            })}
          </div>
        )
      }

      const getButtonStyle = (button, theme) => {
        const buttonType = button.buttonType || 'default'  
  
        return {
          ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
          ...(button.style || {}),
          ...(button.styles || {})
        }
      }

      return (
        <Tooltip key={key}  TransitionComponent={Zoom} title={theButton.tooltip || ''} classes={{ tooltip: classes.tooltipRoot }}>
          <Button disabled={readonly} className={[readonly ? classes.disabled : null, className].filter(el => !!el).join(' ')} variant='contained' color='primary' onClick={() => {
            return this.onButtonClick(key)
          }} style={getButtonStyle(theButton, theme)}>
            {theButton.icon ? (
              <Icon className={classes.buttonIcon}>{theButton.icon}</Icon>
            ) : ''}
            {theButton.label}
          </Button>
        </Tooltip>
      )
    }

    return (
      <div className={classes.root}>
        <div className={classes.checkbox}>
          <div className={classes.checkboxLabel}>
            {checkbox.label}
          </div>
          <div className={classes.checkboxButtonContainer}>
            <div>
              <Checkbox field={{
                value: checkbox.value
              }} readonly={readonly} customClasses={{
                root: classes.checkboxRoot,
                switch: {
                  classes: {
                    root: classes.switchRoot,
                    //switchBase: classes.switchRoot,
                  }
                }
              }} onChange={(action, newField, value) => {
                return onChange({ type: 'update', index: null, property: 'checkbox' }, field, value)
              }} />
            </div>
            {getButton(button)}
          </div>
        </div>
        <div className={classes.dateContainer}>
          <Date field={{
            label: date.label,
            value: date.value
          }} readonly={readonly} onChange={(action, newField, value) => {
            return onChange({ type: 'update', index: null, property: 'date' }, field, value)
          }} />
        </div>
      </div>
    )
  }
}

Print.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Print)))
