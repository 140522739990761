import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AsyncSelect from '../../PageActions/InlineForm/AsyncSelect'
import Select from '../../PageActions/InlineForm/Select'
import Time from '../../PageActions/InlineForm/Time'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import {
  confirmActions,
} from '../../../actions'

const styles = () => ({
  root: {
    marginBottom: '2rem',
    paddingRight: '2.5rem',
    position: 'relative',
    alignItems: 'center'
  },
  selectLabel: {
    top: -6
  },
  selectLabelActive: {
    transform: 'translate(14px, 0)'
  },
  timeInputRoot: {
    margin: 0
  },
  timeInput: {
    padding: '0 14px',
    height: 42
  },
  deleteButton: {
    position: 'absolute',
    right: 0
  }
})

class Worker extends React.Component {
  state = {}

  render() {
    const {
      classes,
      workerHelper,
      companyObj,
      doctors,
      field = {},
      index,
      onChange,
      prevWorkers,
      onDelete,
      theme
    } = this.props

    const {
      time,
      doctor = (companyObj || {}).doctor,
      worker,
      defaultWorker
    } = field

    const options = doctors.map(doctor => {
      return {
        label: doctor.name,
        value: doctor.id
      }
    })

    return (
      <Grid container spacing={24} className={classes.root}>
        <Grid item xs={5}>
          <AsyncSelect
            onChange={selectedWorker => {
              this.setState({
                worker: selectedWorker
              }, )

              onChange({
                ...field,
                worker: selectedWorker
              }, index)
            }}
            field={{
              optionsParams: {
                excludeIds: prevWorkers
              },
              defaultOptions: defaultWorker || null,
              value: worker ? (defaultWorker || []).find(item => item.value === worker) : null,
              options: [
                workerHelper,
                (companyObj || {}).id
              ].join(''),
              label: 'Lavoratore',
              focused: !(!worker),
              classes: {
                label: classes.selectLabel,
                labelActive: classes.selectLabelActive,
                root: (provided) => ({
                  ...provided,
                  marginBottom: 0
                }),
                control: (provided, state) => ({
                  ...provided,
                  borderStyle: 'solid',
                  borderWidth: state.isFocused ? 2 : 1,
                  borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
                  borderRadius: 4,
                  backgroundColor: 'transparent',
                  margin: 0,
                  minHeight: 41,
                  padding: '0 14px',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: theme.palette.select.inputControlBorder,
                  }
                }),
                menuList: (provided) => ({
                  ...provided,
                  backgroundColor: theme.palette.select.inputControlMenuListBackground,
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: theme.palette.select.inputControlMenuListBackground,
                  zIndex: 100,
                  top: 'auto',
                  bottom: '100%',
                  fontSize: '.75rem'
                }),
              }
            }} />
        </Grid>
        <Grid item xs={5}>
          <Select
            onChange={doctor => {
              this.setState({
                doctor
              })

              onChange({
                ...field,
                doctor
              }, index)
            }}
            field={{
              options,
              label: 'Medico',
              value: doctor ? options.find(option => option.value === doctor) : null,
              focused: false,
              classes: {
                label: classes.selectLabel,
                root: (provided) => ({
                  ...provided,
                  marginBottom: 0
                }),
                control: (provided, state) => ({
                  ...provided,
                  borderStyle: 'solid',
                  borderWidth: state.isFocused ? 2 : 1,
                  borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
                  borderRadius: 4,
                  backgroundColor: 'transparent',
                  margin: 0,
                  minHeight: 41,
                  padding: '0 14px',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: theme.palette.select.inputControlBorder,
                  }
                }),
                menuList: (provided) => ({
                  ...provided,
                  backgroundColor: theme.palette.select.inputControlMenuListBackground,
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: theme.palette.select.inputControlMenuListBackground,
                  zIndex: 100,
                  top: 'auto',
                  bottom: '100%'
                }),
              }
            }} />
        </Grid>
        <Grid item xs={2}>
          <Time field={{
            value: time,
            onChange: time => {
              onChange({
                ...field,
                time
              }, index)
            },
            classes: {
              inputRoot: classes.timeInputRoot,
              input: classes.timeInput
            }
          }} />
        </Grid>
        <div className={classes.deleteButton}>
          <IconButton onClick={() => {
            onDelete(index)
          }}>
            <Icon>delete</Icon>
          </IconButton>
        </div>
      </Grid>
    )
  }
}

Worker.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Worker)))
