import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '../../Icon'
import ModalComponent from '../../ModalComponent'
import FormField from '../../FormField'
import Select from './Select'
import Checkbox from './Checkbox'
import moment from 'moment'
import _ from 'lodash'

import {} from '../../../actions'

const styles = () => ({
  modalTitle: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  modalPeriod: {
    display: 'flex',
    padding: '1rem 0 0',
    alignItems: 'center'
  },
  modalPeriodLabel: {
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: '16px 0 8px',
    fontSize: 'clamp(12px, 13px, 17px)'
  },
  modalPeriodElement: {
    flexGrow: 1
  },
  listTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 'clamp(10px, 16px, 18px)'
  },
  buttonInModal: {
    margin: '0 1rem',
  },
  yearInModal: {
    margin: '0 1rem',
    backgroundColor: '#f0ad4e',
    color: '#fff'
  },
  label: {
    marginLeft: '.5rem'
  },
  actionRoot: {
    display: 'flex'
  },
  param: {
    flexGrow: 1
  }
})

class ModalActions extends React.Component {
  state = {
    params: {}
  }

  render() {
    const { classes, openModal, currentModalAction, onClose, onClick, parent } = this.props

    const { periodFrom, periodTo } = parent.state

    const { params } = this.state

    const currentYear = parseInt(moment().format('YYYY'))

    const years = []

    for (var i = 0; i < 6; i++) {
      years.unshift(currentYear - i)
    }

    return (
      <ModalComponent
        open={openModal}
        onClose={onClose}
        header={(
          <div>
            <div className={classes.modalTitle}>
              {currentModalAction.label}
            </div>
            <div className={classes.modalPeriod}>
              <div className={classes.modalPeriodLabel}>{currentModalAction.filtersLabel || 'Stampa documenti:'} </div>
              <div className={classes.modalPeriodElement}>
                <FormField field={{
                  label: 'Dal',
                  value: periodFrom,
                  maxDate: periodTo,
                  type: 'date'
                }} onChange={(action, field, value) => {
                  return parent.setState({
                    periodFrom: value
                  })
                }} />
              </div>
              <div className={classes.modalPeriodElement}>
                <FormField field={{
                  label: 'Al',
                  value: periodTo,
                  minDate: periodFrom,
                  type: 'date'
                }} onChange={(action, field, value) => {
                  return parent.setState({
                    periodTo: value
                  })
                }} />
              </div>
            </div>
            {!currentModalAction.hidePeriod && (
              <div className={classes.modalPeriod}>
                <div className={classes.modalPeriodLabel}>Oppure tutto il: </div>
                <div className={classes.modalPeriodElement}>
                  {years.map((year, index) => {
                    return (
                      <Button
                        variant='contained'
                        className={classes.yearInModal}
                        onClick={() => {
                          return parent.setState({
                            periodFrom: '01/01/' + year,
                            periodTo: '31/12/' + year
                          })
                        }}
                        key={index} >{year}</Button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        body={(
          <div>
            <Grid container spacing={24}>
              {currentModalAction.actions.map((group, groupIndex) => {
                return (
                  <Grid item xs={12} key={groupIndex}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} className={classes.listTitle}>
                        {group.label}
                      </Grid>
                      {group.actions.map((action, actionIndex) => {
                        return (
                          <Grid key={actionIndex} item xs={action.size}>
                            <div className={classes.actionRoot}>
                              <Button variant='contained' className={classes.buttonInModal} onClick={() => onClick(action, currentModalAction, params)} style={action.style || {
                                backgroundColor: '#428bca',
                                color: '#fff'
                              }}>
                                <Icon>{action.icon || 'print'}</Icon>
                                <span className={classes.label}>{action.label}</span>
                              </Button>
                              {action.withParams ? action.withParams.map((param, paramIndex) => {
                                switch (param.type) {
                                  case 'select':
                                    return (
                                      <div className={classes.param} key={paramIndex}>
                                        <Select config={param} onChange={(name, value) => {
                                          const newParams = _.cloneDeep(params)

                                          newParams[name] = value

                                          return this.setState({
                                            params: newParams
                                          })
                                        }} />
                                      </div>
                                    )
                                  case 'checkbox':
                                    return (
                                      <div className={classes.param} key={paramIndex}>
                                        <Checkbox field={param} onChange={(name, value) => {
                                          const newParams = _.cloneDeep(params)

                                          newParams[name] = value

                                          return this.setState({
                                            params: newParams
                                          })
                                        }} />
                                      </div>
                                    )
                                  default:
                                    return ''
                                }

                              }) : null}
                            </div>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )} >
      </ModalComponent>
    )
  }
}

ModalActions.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ModalActions)))
