import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { Grid, IconButton, Paper, Tooltip} from '@material-ui/core'
import draftToHtml from 'draftjs-to-html'
import Icon from '../Icon'
import MUIcon from '@material-ui/core/Icon'

import {dashboardActions} from '../../actions'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 2,
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    '& .RichText-editor': {
      minHeight: '380px'
    },
    borderRadius: '10px !important',
  },
  rootTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0 0 1rem',
    cursor: 'pointer',
    minHeight: '465px',
    wordWrap: 'break-word'
  },
  textRight: {
    textAlign: 'right'
  },
  noteCancelButton: {
    color: theme.palette.notefield.textWhite,
    fontWeight: 700,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  noteSubmitButton: {
    backgroundColor: theme.palette.success[100],
    color: theme.palette.notefield.textWhite,
    fontWeight: 700,
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.success[200],
    }
  },
  closeButton: {
    float: 'right',
    zIndex: 1
  },
  editorToolbar: {    
    borderRadius: '5px',
    border: 'none',
    backgroundColor: theme.palette.notefield.editorToolbar,
    '& .rdw-option-wrapper': {
      backgroundColor: theme.palette.notefield.wrappersBackground,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.notefield.optionHover
      },
      '&.rdw-option-active': {
        backgroundColor: theme.palette.notefield.optionActive,
      },
      '&.rdw-option-disabled': {
        opacity: '0.6',
        cursor: 'not-allowed'
      },
    },
    '& .rdw-dropdown-wrapper': {
      backgroundColor: theme.palette.notefield.wrappersBackground,
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.notefield.optionHover
      },
    },
    '& .rdw-link-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-colorpicker-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-emoji-modal': {
      border: theme.palette.notefield.borderEmojiModal,
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.wrappersBackground,
    },
    '& .rdw-link-modal-btn': {
      backgroundColor: theme.palette.notefield.btn,
      color: theme.palette.notefield.textWhite,
      fontWeight: 700,
      border: 'none',
      boxShadow: 'none',
      '&:disabled': {
        backgroundColor: theme.palette.notefield.btnDisabled,
        cursor: 'not-allowed'
      },
      '&:not(:disabled):hover': {
        backgroundColor: theme.palette.notefield.btnHover,
      },
    },
    '& .rdw-dropdown-optionwrapper': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.notefield.dropdownBackground,
      '&:hover': {
        boxShadow: 'none'
      },
    },
    '& .rdw-dropdownoption-highlighted': {
      backgroundColor: theme.palette.notefield.dropdownHighlighted
    },
    '& .rdw-dropdown-selectedtext': {
      color: theme.palette.notefield.dropdownTextSelected
    },
    '& .rdw-link-modal-input': {
      border: theme.palette.notefield.border,
      backgroundColor: theme.palette.notefield.linkModalInput,
      color: theme.palette.notefield.textReverse,
    },
    '& .rdw-dropdownoption-active ': {
      backgroundColor: theme.palette.notefield.dropdownOptionActive
    }
  }
})

class NoteField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      noteText: '',
      editorState: null
    }
  }

  componentDidMount = () => {
    const { item } = this.props

    this.setState({
      noteText: item.text,
      editorState: this.getStateFromHtml(item.text),
    })
  }

  getStateFromHtml = (html) => {
    return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks))
  }

  handleClick = () => {
    this.setState({
      isEditing: true,
      editorState: EditorState.moveFocusToEnd(this.state.editorState)
    })
  }

  removeEmptyTags = (html) => {
    return html.replace(/<p><\/p>/g, '').trim()
  }

  onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())

    const noteText = this.removeEmptyTags(draftToHtml(rawContentState))

    this.setState({
      editorState,
      noteText
    })
  }

  saveNote = (data, url) => {
    const cleanedData = this.removeEmptyTags(data)

    return this.setState({
      isEditing: false,
      noteText: cleanedData
    }, () => {
      this.props.onNoteSave(cleanedData, url)
    })
  }

  render() {
    const { isEditing, noteText, editorState } = this.state

    const { classes, item } = this.props

    return (
      <Paper className={classes.root} elevation={0}>
        {isEditing ? (
          <Editor
            editorState={editorState}
            toolbarClassName={classes.editorToolbar}
            wrapperClassName='RichText-wrapper'
            editorClassName='RichText-editor'
            onEditorStateChange={(editorState) => {
              const noteText = (draftToHtml(convertToRaw(editorState.getCurrentContent()))).trim()

              this.setState({
                editorState,
                noteText
              })
            }}
            toolbar={{
              options: ['inline', 'fontSize', 'colorPicker', 'emoji', 'list', 'textAlign', 'link', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              fontSize: {
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
              list: {
                options: ['unordered', 'ordered'],
              },
              textAlign: {
                options: ['left', 'center', 'right', 'justify'],
              },
            }}
          />
        ) : (
          <>
            {noteText && noteText.replace(/<p><\/p>/gi, '').trim() ? (
              <div>
                <Tooltip title='Elimina' classes={{ tooltip: classes.tooltipRoot }}>
                  <IconButton className={classes.closeButton} onClick={() => {
                    this.setState({
                      noteText: '',
                      editorState: this.getStateFromHtml('')
                    }, () => {
                      this.saveNote('', item.action)
                    })
                  }}>
                    <MUIcon>close</MUIcon>
                  </IconButton>
                </Tooltip>
                <div className={classes.rootTitle} onClick={this.handleClick}>
                  <div dangerouslySetInnerHTML={{ __html: noteText || '' }} />
                </div>
              </div>
            ) : (
              <div className={classes.rootTitle} onClick={this.handleClick}>
                {item.text ? item.text : <><Icon>{item.icon}</Icon> {item.title}</>}
              </div>
            )}
          </>
        )}

        {isEditing && (
          <>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  onClick={() => this.saveNote(noteText, item.action)}
                  className={classes.noteSubmitButton}>
                  Salva
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.textRight}>
                <Button
                  variant='contained'
                  onClick={() => this.setState({
                    isEditing: false,
                    noteText: item.text,
                    editorState: this.getStateFromHtml(item.text)
                  })}
                  className={classes.noteCancelButton}>
                  Annulla
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    )
  }

}

NoteField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({...state.appReducer})

const mapDispatchToProps = dispatch => ({
  onNoteSave: (data, url) => {
    dispatch(dashboardActions.saveNotePost(data, url))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(NoteField)))
