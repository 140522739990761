import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { validators } from '../Validators'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import FormHelperText from '@material-ui/core/FormHelperText'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import { ajaxCall } from '../../../utils'
import LabelWithAsterisk from '../LabelWithAsterisk'
import _ from 'lodash'

import {
  commonActions
} from '../../../actions'

const styles = (theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -1,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -6px)',
    padding: '0 3px'
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  },
  selectContainer: {
    display: 'flex',
  },
  select: {
    flexGrow: 1
  },
  button: {
    padding: 8
  }
})

const customStyles = (config, isColored, theme) => ({
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: state.isFocused ? 2 : 1,
    borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : (state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover),
    borderRadius: 4,
    backgroundColor: state.isDisabled ? theme.palette.background.disabled : (isColored ? theme.palette.background.colored : theme.palette.background.controlSelect),
    margin: 0,
    opacity: 1,
    minHeight: 41,
    padding: '0 14px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : theme.palette.select.inputControlBorder,
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: config.dangerIfEmpty && config.isEmpty ? theme.palette.error.main : theme.palette.select.inputControlPlaceholderText,
    fontWeight: config.dangerIfEmpty && config.isEmpty ? 700 : 400
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    backgroundColor: theme.palette.select.inputControlMenuListBackground
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    color: config.error ? theme.palette.error.main : theme.palette.text.primary,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    fontSize: '0.9285714285714286rem',
    color: config.error ? theme.palette.error.main : theme.palette.text.primary,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: theme.palette.select.inputControlMenuListBackground,
    color: theme.palette.select.text,
    cursor: 'pointer',
    '&:active': {
      backgroundColor: theme.palette.select.inputControlElementSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.select.inputControlElementHover,
    }
  }),
  input: (provided) => ({
    ...provided,
    color: config.error ? theme.palette.error.main : theme.palette.text.primary
  })
})

class AsyncCreatableSelectField extends React.Component {
  state = {
    error: false,
    isEmpty: false,
    errorMessage: null,
    focused: false,
    selectedValue: '',
  }

  componentDidMount() {
    const { value, values } = this.props.field

    const newState = {}

    if (values) {
      newState.selectedValue = values.length ? values : ''

      newState.isEmpty = !newState.selectedValue
    } else if (value) {
      newState.selectedValue = value

      newState.isEmpty = false
    } else {
      newState.isEmpty = true
    }

    this.setState(newState)

    return validators.mount(this)
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps.field

    const { field, onChange } = this.props

    const { value } = field

    const { selectedValue } = this.state

    let changed = false

    if (_.isObject(value) && value.value !== selectedValue.value) {
      changed = value.value !== prevValue.value
    } else if (typeof value === 'string' && value !== selectedValue) {
      changed = value !== prevValue
    }

    if (changed) {
      return this.setState({
        selectedValue: value || '',
        isEmpty: !value
      }, () => {
        onChange({type: 'select'}, field, value)
      })
    }

    return validators.update(this, prevProps)
  }

  addToOptions = (res) => {
    const { field, onChange } = this.props

    const { creatable } = field

    const { selectedValue } = this.state

    if (res.status === 'success') {
      const currentValue = {
        label: selectedValue.label,
        value: res.data[creatable.fieldValue]
      }

      this.setState({
        selectedValue: currentValue
      })

      return onChange({type: 'select'}, field, currentValue)
    }
  }

  onClick = () => {
    const { creatable } = this.props.field

    const { selectedValue } = this.state

    if (selectedValue.__isNew__) {
      this.props.onCall({
        url: creatable.url,
        data: {
          fields: [
            {
              name: creatable.fieldName,
              value: selectedValue.value
            }
          ]
        }
      }, this.addToOptions)
    }
  }

  filterOptions = (options, inputValue) => {
    return options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  promiseOptions = (inputValue, callback) => {
    const { field } = this.props

    ajaxCall({
      config: {
        url: field.options,
        method: 'get',
        params: {
          query: inputValue
        }
      },
      success: (res) => {
        callback(this.filterOptions(res.data.data, inputValue))
      },
      error: (res) => {
        // TODO
      },
      activeLoading: false
    })
  }

  render() {
    const { field, classes, onChange, noOptionsMessage, readonly, theme } = this.props

    const { creatable } = field

    const { focused, selectedValue, isEmpty } = this.state

    const activeLabel = selectedValue ? true : focused

    const labelActive = (activeLabel ? ' ' + classes.labelActive : '')

    const props = {}

    const fieldReadonly = readonly || field.readonly

    if (noOptionsMessage) {
      props.noOptionsMessage = () => noOptionsMessage.noResults
    }

    return (
      <FormControl fullWidth={true} error={field.error ? true : false} className={classes.root}>
        <InputLabel variant='outlined' className={classes.label + labelActive}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></InputLabel>
        <div className={classes.selectContainer}>
          <div className={classes.select}>
            <AsyncCreatableSelect
              styles={customStyles({
                error: field.error ? true : false,
                dangerIfEmpty: field.dangerIfEmpty,
                isEmpty
              }, selectedValue && selectedValue.isColored, theme)}
              loadOptions={_.debounce(this.promiseOptions, 500)}
              formatCreateLabel={(inputValue) => {
                return 'Crea "' + inputValue + '"'
              }}
              placeholder={field.placeholder || false}
              isDisabled={fieldReadonly || field.disabled}
              isClearable={field.isClearable ? true : false}
              value={selectedValue || ''}
              onChange={(value) => {
                this.setState({
                  selectedValue: value,
                  isEmpty: !value
                })

                return onChange({ type: 'select', fromState: false }, field, value)
              }}
              onBlur={() => this.setState({focused: false})}
              onFocus={() => this.setState({focused: true})}
              {...props} />
          </div>
          <Tooltip TransitionComponent={Zoom} title={creatable.tooltip || ''} classes={{ tooltip: classes.tooltipRoot }}>
            <div>
              <IconButton className={classes.button} onClick={this.onClick}><Icon>save</Icon></IconButton>
            </div>
          </Tooltip>
        </div>
        {field.error ? (
          <FormHelperText className={classes.formHelperText}>{field.error}</FormHelperText>
        ) : ''}
      </FormControl>
    )
  }
}

AsyncCreatableSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onCall: (config, callback) => {
    dispatch(commonActions.callPost(config, callback))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AsyncCreatableSelectField)))
