import store from '../stores'
import _ from 'lodash'
import { getPaginationConfig, setPaginationConfig } from '../utils/pagination'

import {
  listPageAjaxActions,
  messageActions
} from '../actions'

const initialState = {
  paginationConfig: {}
}

//Vars
let newState = null
let newList = null

const paginationConfigReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'inline_form_reset_params':
      newState = _.cloneDeep(state)

      return {
        ...setPaginationConfig(newState, null, {
          selectCache: {},
          isReset: true
        })
      }
    case 'inlineFormActions.cacheSelect':
      newState = _.cloneDeep(state)

      let {
        selectCache
      } = getPaginationConfig(newState.paginationConfig)

      if (!selectCache) {
        selectCache = {}
      }

      if (action.selectedValue === null) {
        delete selectCache[action.fieldName]
      } else {
        selectCache[action.fieldName] = action.selectedValue
      }

      return {
        ...setPaginationConfig(newState, null, {
          selectCache
        })
      }
    case 'pagination_config_init_pagination_config':
      return {
        ...setPaginationConfig(_.cloneDeep(state))
      }
    case 'list_page_ajax_action_pagination_config':
    case 'list_page_action_pagination_config':
      newState = _.cloneDeep(state)

      return {
        ...setPaginationConfig(newState, action.paginationConfig)
      }
    case 'list_page_ajax_action_update_selected_elements':
    case 'list_page_action_update_selected_elements':
      newState = {
        ..._.cloneDeep(state),
      }

      return {
        ...setPaginationConfig(newState, null, {
          selectedElements: action.selectedElements,
          selectAllPageElements: !(!action.selectAllPageElements)
        })
      }
    case 'list_page_ajax_action_list_pages':
      newList = action.data.data

      newState = {
        ..._.cloneDeep(state),
        listPagesAjax: (newList || {}).models,
        listPageAjaxReload: false,
        requestPagination: action.data.pagination
      }

      return {
        ...setPaginationConfig(newState, null, {
          ...newList.pagination,
          ...action.params,
        })
      }
    case 'list_page_action_list_pages':
      newList = action.append ? _.cloneDeep(state.listPages) || [] : action.data.data

      if (action.append) {
        newList.push(...action.data.data)
      }

      newState = {
        ..._.cloneDeep(state),
        listPages: newList,
        listPageReload: false,
        requestPagination: action.data.pagination
      }

      return {
        ...setPaginationConfig(newState, null, action.params)
      }
    case 'list_page_action_list_page_change':
      newState = _.cloneDeep(state)

      newState.listPages = newState.listPages.map(item => {
        if (item.id === action.itemId) {
          item = _.cloneDeep(action.data.data)
        }

        return item
      })

      return newState
    case 'list_page_ajax_action_list_page_change':
      newState = _.cloneDeep(state)

      newState.listPagesAjax = newState.listPagesAjax.map(item => {
        if (item.id === action.itemId) {
          item = _.cloneDeep(action.data.data)
        }

        return item
      })

      return newState
    case 'list_page_action_list_page_delete_post':
      return {
        ...state,
        reloadPageTemplate: false
      }
    case 'list_page_action_list_page_delete':
      newState = {
        ..._.cloneDeep(state),
      }

      newState.reloadPageTemplate = action.reloadPageTemplate

      newState.listPages = (newState.listPages || []).filter(listPage => {
        let canDelete = true

        if (listPage.innerElements && listPage.innerElements.body.length) {
          listPage.innerElements.body = listPage.innerElements.body.filter(bodyItem => {
            return (bodyItem.idIndex ? bodyItem.fields[bodyItem.idIndex] : bodyItem.fields.id) !== action.itemId
          })

          canDelete = !listPage.innerElements.body.length
        }

        return !canDelete ? true : listPage.id !== action.itemId
      })

      setTimeout(() => {
        return store.dispatch(messageActions.showMessage({
          type: 'success',
          message: action.data.message}
        ))
      }, 200)

      return newState
    case 'list_page_ajax_action_list_page_delete':
      newState = {
        ..._.cloneDeep(state),
      }

      setTimeout(() => {
        store.dispatch(listPageAjaxActions.listPagesAjaxGet(action.pageType, action.pageTypes, getPaginationConfig(newState.paginationConfig), false))

        return store.dispatch(messageActions.showMessage({
          type: 'success',
          message: action.data.message}
        ))
      }, 200)

      return newState
    case 'list_page_ajax_action_reload_list':
      newState = {
        ..._.cloneDeep(state),
        listPageAjaxReload: true
      }

      return newState
    default:
      return state
  }
}

export default paginationConfigReducer
