import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import 'moment/locale/it'
import _ from 'lodash'
import store from '../../stores'

const styles = theme => {
  return {
    rootClick: {
      cursor: 'pointer',
      height: '100%'
    },
    root: {
      borderRadius: '10px !important',
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 500,
    },
    rootTitle: {
      fontSize: '1.5rem',
      fontWeight: 500,
      padding: '0 0 1rem'
    },
    rootContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    noEventDay: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: '1.5rem',
      height: '100%',
      alignItems: 'center',
      fontWeight: 300,
      padding: '0 0 1rem'
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    notificationBadge: {
      position: 'absolute',
      zIndex: 2,
      right: '3px',
      top: '-3px',
      width: '12px',
      height: '12px',
      padding: 0,
      borderRadius: '50%'
    },
    confirmed: {
      backgroundColor: theme.palette.success.main,
      border: '2px solid white'
    },
    notConfirmed: {
      backgroundColor: theme.palette.error.main,
      border: '2px solid white'
    },
    dayCalendarHourEvents: {
      display: 'flex',
      paddingLeft: '.5rem',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    calendarDayEvent: {
      padding: '.25rem .375rem',
      marginRight: '.5rem',
      borderRadius: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: 700,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      marginBottom: '.25rem',
      position: 'relative',
      zIndex: 1,
      transition: 'all .125s ease-in-out',
      '&:hover': {
        boxShadow: theme.shadows[1]
      }
    },
    calendarDayEventCompany: {
      backgroundColor: '#1565c0'
    },
    dayCalendarHourLine: {
      width: '100%',
      textAlign: 'center',
      borderBottom: '1px solid ' + theme.palette.grey[100],
      lineHeight: '0.1em',
      margin: '10px 0 20px'
    },
    dayCalendarHour: {
      background: theme.palette.agendaWidget.dayCalendarHour,
      padding: '0 10px'
    },
    calendarTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5rem',
      height: 60,
      position: 'relative'
    },
    dayCalendarAllDayEvents: {
      padding: '8px 0 8px'
    },
    calendarDayEventTimetable: {
      backgroundColor: '#ffb300',
      color: theme.palette.common.black,
    },
    calendarDayEventMonitoringtimetable: {
      backgroundColor: '#8d6e63',
      color: theme.palette.common.white,
    },
    calendarDayEventCoursetimetable: {
      backgroundColor: '#78909c',
      color: theme.palette.common.white,
    },
    calendarDayEventInspectiontimetable: {
      backgroundColor: '#0d8ba0',
      color: theme.palette.common.white,
    },
    calendarDayEventSafetytimetable: {
      backgroundColor: '#8b52ab',
      color: theme.palette.common.white,
    }
  }
}

class Agenda extends React.Component {
  state = {
    dayEvents: {},
  }

  orderEvents = (a, b) => {
    if (a.orderableDate > b.orderableDate) {
      return 1
    }

    if (a.orderableDate < b.orderableDate) {
      return -1
    }

    return 0
  }

  dayEvents = events => {
    const unorderedEvents = []

    const allDayEvents = []

    events.forEach(event => {
      if (event.time) {
        const eventOrderableDate = moment([
          event.date,
          event.time
        ].join(' '), window.appConfig.dateFormat + ' HH:mm')

        const tmpEvents = [{
          ...event,
          orderableDate: eventOrderableDate,
          workerEvents: []
        }]

        if (event.workers) {
          event.workers.filter(worker => {
            return !(!worker.time)
          }).forEach(worker => {
            const workerOrderableDate = moment([
              event.date,
              worker.time
            ].join(' '), window.appConfig.dateFormat + ' HH:mm')

            return tmpEvents[0].workerEvents.push({
              ...worker,
              eventId: event.id,
              isWorker: true,
              title: [
                [
                  worker.worker.name,
                  worker.worker.cf
                ].join(' - '),
                [
                  '(',
                  worker.doctor.name,
                  ')'
                ].join('')
              ].join(' '),
              orderableDate: workerOrderableDate
            })
          })
        }

        return unorderedEvents.push(...tmpEvents)
      }

      allDayEvents.push(event)
    })

    unorderedEvents.sort(this.orderEvents)

    const hours = []

    for (let i = 0; i < 24; i++) {
      hours.push({
        hour: i,
        events: unorderedEvents.filter(event => {
          return parseInt(event.orderableDate.format('H')) === i && (!event.workers.length || event.workerEvents.length)
        })
      })
    }

    this.setState({
      dayEvents: {
        allDayEvents,
        hours
      }
    })
  }

  getTimeEvent = event => {
    const {
      classes
    } = this.props

    return (
      <div className={classes.wrapper}>
        {event.type === 'event' && (
          <div className={`${classes.notificationBadge} ${event.evento_confermato === 1 ? classes.confirmed : classes.notConfirmed}`} />
        )}
        <div className={classes.dayCalendarHourEventContainer}>
          <div className={classes.dayCalendarHourEvent}>
            <div className={[
              classes.calendarDayEvent,
              classes['calendarDayEvent' + _.capitalize(event.type)],
              event.id_azienda && event.type === 'event' ? classes.calendarDayEventCompany : ''
            ].join(' ')}>
              {this.formatEvent(event)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  formatEvent = event => {
    const { time, workerEvents, company, type } = event

    const { classes } = this.props

    let duration

    let title

    if (time && !(workerEvents || []).length) {
      duration = time
    }

    if (workerEvents && workerEvents.length) {
      const start = workerEvents[0].time || ''

      const end = workerEvents.length > 1 ? (workerEvents[workerEvents.length - 1].time || '') : ''

      duration = end ? [start, end].join(' - ') : start
    }

    duration = duration ? ('(' + duration + ')') : ''

    if (company && type === 'event') {
      const workers = (workerEvents || []).length

      const plural = workers && workers > 1

      const toBeVisited = workers ? <span>(<small>{workers} {('lavorator' + (plural ? 'i' : 'e'))} {('convocat' + (plural ? 'i' : 'o'))}</small>)</span> : ''

      title = (
        <div className={classes.companyDayCalendarEvent}>
          {duration} {company.name} {toBeVisited}
        </div>
      )
    } else {
      title = <div className={classes.genericDayCalendarEvent}>
        {duration} {event.title}
      </div>
    }

    return title
  }

  getEventsSpan = events => {
    if (!events || !events.length) {
      return {}
    }

    const start = events[0].orderableDate.format('H')

    let end = start

    events.forEach(event => {
      if (event.workerEvents && event.workerEvents.length) {
        const tmpEnd = event.workerEvents[event.workerEvents.length - 1].orderableDate.format('H')

        if (end < tmpEnd) {
          end = tmpEnd
        }
      }
    })

    const span = { eventStart: parseInt(start), eventEnd: parseInt(end) }

    return span
  }

  setHourSlots = (hours) => {
    const slots = []

    const lastSlotInfo = (slots) => {
      const lastSlotIndex = slots.length - 1

      return {
        lastSlotIndex,
        lastSlotIsEmpty: slots.length && slots[lastSlotIndex].empty,
        lastSlotStart: slots.length ? slots[lastSlotIndex].startSlot : -1,
        lastSlotEnd: slots.length ? slots[lastSlotIndex].endSlot : 100
      }
    }

    hours.forEach((hourObj) => {
      const { events, hour } = hourObj

      const { lastSlotIndex, lastSlotIsEmpty, lastSlotStart, lastSlotEnd } = lastSlotInfo(slots)

      if (!events.length) {
        if (!slots.length || (!lastSlotIsEmpty && lastSlotEnd < hour)) {
          slots.push({
            startSlot: hour,
            endSlot: hour,
            empty: true
          })
        } else {
          if (lastSlotIsEmpty) {
            slots[lastSlotIndex].endSlot = hour
          }
        }
      }

      if (events.length) {
        const { eventStart, eventEnd = -1 } = this.getEventsSpan(events)

        if (!slots.length || lastSlotIsEmpty || (!lastSlotIsEmpty && lastSlotEnd === lastSlotStart)) {
          slots.push({
            startSlot: eventStart,
            endSlot: eventEnd,
            empty: false,
            events: [...events]
          })
        } else {
          if (lastSlotEnd < eventEnd && lastSlotEnd !== lastSlotStart) {
            slots[lastSlotIndex].endSlot = eventEnd
          }

          slots[lastSlotIndex].events.push(...events)
        }
      }
    })

    return slots.map(slot => {
      return {
        ...slot,
        single: slot.startSlot === slot.endSlot
      }
    })
  }

  groupHours = (hours) => {
    const { classes } = this.props

    const sections = []

    this.setHourSlots(hours).forEach(slot => {
      const { startSlot, endSlot, single, empty, events } = slot

      const start = startSlot

      const end = single ? '' : (' - ' + endSlot)

      sections.push(
        <div key={sections.length}>
          <div className={classes.dayCalendarHourLine}>
            <span className={classes.dayCalendarHour}>
              {start + end}
            </span>
          </div>

          {!empty ? <div className={classes.dayCalendarHourEvents}>
            {events.map((event, eventIndex) => {
              return (<div key={eventIndex}>{this.getTimeEvent(event)}</div>)
            })}
          </div> : ''}
        </div>
      )
    })

    return sections
  }

  checkDayHourEvents = (hours) => (hours || []).find(hour => hour.events.length)

  checkAllDayEvents = (allDayEvents) => allDayEvents && allDayEvents.length

  checkDayEvents = (dayEvents) => this.checkDayHourEvents(dayEvents.hours) || this.checkAllDayEvents(dayEvents.allDayEvents)

  componentDidMount() {
    const { agenda } = this.props.item

    this.dayEvents(agenda.events)
  }

  componentDidUpdate = prevProps => {
    const { agenda: prevAgenda } = prevProps.item

    const { agenda } = this.props.item

    if (prevAgenda.events !== agenda.events) {
      this.dayEvents(agenda.events)
    }
  }

  render() {
    const {
      dayEvents,
    } = this.state

    moment.locale('it')

    const { classes, item } = this.props

    const { appConfig } = store.getState().appReducer

    return (
      <div className={classes.rootClick} onClick={() => this.props.history.push(appConfig.appPrefix + '/' + (item.goTo || 'agenda'))}>
        <Paper className={classes.root} elevation={0}>
          <div className={classes.rootTitle}>
            {[item.title ? item.title : '', moment().format('DD MMMM YYYY')].join(' - ')
            }
          </div>
          <div className={classes.rootContent}>
            <div className={classes.dayCalendarRoot}>
              {dayEvents.allDayEvents ? (
                <div className={classes.dayCalendarAllDayEvents}>
                  {dayEvents.allDayEvents.map((allDayEvent, index) => {
                    return (<div key={index}>{this.getTimeEvent(allDayEvent)}</div>)
                  })}
                </div>
              ) : ''}
              {this.groupHours(dayEvents.hours || [])}
            </div>
            <div className={classes.noEventDay}>{!this.checkDayEvents(dayEvents) ?
              <span>Nessun evento programmato per oggi.<br />
                <small>Clicca per visualizzare gli eventi di questo mese.</small>
              </span> : ''}
            </div>
          </div>
        </Paper>
      </div >
    )
  }
}

Agenda.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Agenda)))
