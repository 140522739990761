import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'

const styles = theme => ({
  root: {
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.default,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  item: {
    flexGrow: 1
  },
  inline: {
    flexDirection: 'row',
    //flexWrap: 'wrap'
  }
})

class Multiline extends React.Component {
  render() {
    const { classes, cell, fieldPath, isActive, onChange, rowIsActive, readonly } = this.props

    const { inline } = cell

    return (
      <div className={classes.root + (inline ? ' ' + classes.inline : '')}>
        {cell.data.map((field, index) => {
          return (
            <BaseField key={index} cell={field} rowIsActive={rowIsActive} isActive={isActive} fieldPath={[...fieldPath, 'data', index]} onChange={onChange} {...{readonly}} />
          )
        })}
      </div>
    )
  }
}

Multiline.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Multiline)))
