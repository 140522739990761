import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import LabelWithAsterisk from './LabelWithAsterisk'

import {} from '../../actions'

const styles = (theme) => ({
  root: {
    padding: '.5rem 0 0 .5rem',
    border: '1px solid #ccc',
    borderRadius: 4,
    flexGrow: 1,
    marginBottom: 0,
    position: 'relative'
  },
  label: {
    backgroundColor: theme.palette.background.muiPaper,
    padding: '0 3px',
    position: 'absolute',
    fontSize: '0.9285714285714286rem',
    lineHeight: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -7,
    left: 13,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 2,
      top: 6,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  chip: {
    margin: '0 .5rem .5rem 0',
    backgroundColor: theme.palette.background.chip,
    padding: '3px 6px'
  }
})

class Tags extends React.Component {
  render() {
    const { classes, field } = this.props

    const values = (field.value || '').split(' --- ').filter(item => {
      return item ? true : false
    })

    return (
      <div className={classes.root}>
        <label className={classes.label}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></label>
        {values.map((item, index) => {
          return (
            <div key={index} className={classes.chip}>{item}</div>
          )
        })}
      </div>
    )
  }
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Tags)))
