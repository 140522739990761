/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Paper } from '@material-ui/core'

import {} from '../../actions'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 2,
    minHeight: '100%',
    display: 'flex',
    borderRadius: '10px !important',
    flexDirection: 'column',
    '& .RichText-editor': {
      minHeight: '380px'
    }
  },
  progressBackground: {
    backgroundColor: theme.palette.progressField.bar,
    borderRadius: '10px',
    overflow: 'hidden',
  },
  barColorPrimary: {
    backgroundColor: '#76ff03',
  },
  rootTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0 0 1rem',
    cursor: 'pointer',
    wordWrap: 'break-word'
  },
  updateItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 2,
    fontSize: '1.3rem',
  },
  updatePercentage: {
    height: '30px',
    borderRadius: '10px',
    marginTop: theme.spacing.unit,
  },
  updatePercentagesLabel: {
    marginLeft: theme.spacing.unit,
  },
  greenProgress: {
    backgroundColor: '#2fbe75',
  },
  yellowProgress: {
    backgroundColor: '#f9db86',
  },
  orangeProgress: {
    backgroundColor: '#fa740c',
  },
  redProgress: {
    backgroundColor: '#e4092e',
  }
})

class ProgressField extends React.Component {
  getColorClass(percentage) {
    const { classes, colorThresholds } = this.props

    if (colorThresholds) {
      for (const threshold of colorThresholds) {
        if (percentage <= threshold.value) {
          return threshold.class
        }
      }
      return colorThresholds[colorThresholds.length - 1].class
    }

    if (percentage < 50) return classes.greenProgress

    if (percentage < 75) return classes.yellowProgress
    
    if (percentage < 100) return classes.orangeProgress
    return classes.redProgress
  }

  render() {
    const { 
      classes, 
      title,
      value,
      maxValue,
      unit = '',
      labelFormatter,
      hideLabel
    } = this.props

    const percentage = (value / maxValue) * 100
    
    const progressClass = this.getColorClass(percentage)
    
    const defaultLabel = `${value.toFixed(2)}${unit} su ${maxValue}${unit}`

    const label = labelFormatter ? labelFormatter(value, maxValue, unit) : defaultLabel

    return (
      <Paper className={classes.root} elevation={0}>
        <div className={classes.rootTitle}>
          {title}
        </div>
        {!hideLabel && (
          <div className={classes.updateItem}>
            <div className={classes.updatePercentagesLabel}>
              {label}
            </div>
          </div>
        )}
        <LinearProgress
          classes={{
            root: classes.progressBackground,
            barColorPrimary: progressClass
          }}
          className={classes.updatePercentage}
          variant="determinate"
          value={percentage}
        />
      </Paper>
    )
  }
}

ProgressField.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  unit: PropTypes.string,
  hideLabel: PropTypes.bool,
  labelFormatter: PropTypes.func,
  colorThresholds: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    class: PropTypes.string
  }))
}

const mapStateToProps = state => ({ ...state.updateReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ProgressField)))
