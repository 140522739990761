import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {} from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontSize: '.75rem'
  },
  readonly: {
    fontSize: '.875rem',
    padding: '.75rem',
    border: '1px solid ' + theme.palette.border.secondary,
    backgroundColor: theme.palette.background.readOnly,
    borderRadius: 4,
    cursor: 'not-allowed'
  }
})

class Info extends React.Component {
  render() {
    const { field, classes } = this.props

    const { value, readonly } = field

    return (
      <div className={[
        classes.root,
        readonly ? classes.readonly : ''
      ].join(' ')} dangerouslySetInnerHTML={{__html: value || '&nbsp;'}}></div>
    )
  }
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Info)))
