import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'

import {} from '../../actions'

const styles = () => ({
  button: {
    flexGrow: 1,
    margin: '0 .5rem 1rem',
    transition: 'opacity .3s ease-in-out'
  },
  label: {
    marginLeft: '.5rem'
  },
  disabled: {
    opacity: '.5'
  },
})

class Nav extends React.Component {
  render() {
    const { classes, action, enabled, appConfig, theme } = this.props

    const { icon, label } = action

    const getButtonStyle = (button, theme) => {
      const buttonType = button.buttonType || 'default'  

      return {
        ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
        ...(button.style || {}),
        ...(button.styles || {})
      }
    }

    return (
      <Button variant='contained' disabled={!enabled} className={classes.button + (!enabled ? ' ' + classes.disabled : '')} style={getButtonStyle(action, theme)} component={NavLink} to={appConfig.appPrefix + action.action}>
        {icon ? (
          <Icon>{icon}</Icon>
        ) : null}
        <span className={classes.label}>{label}</span>
      </Button>
    )
  }
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Nav)))
