import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUITableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MUIIcon from '@material-ui/core/Icon'
import Icon from '../Icon'
import TableActions from './TableActions'
import Tooltip from '@material-ui/core/Tooltip'

import {} from '../../actions'

const styles = theme => ({
  title: {
    padding: '0 1rem'
  },
  innerElements: {
    fontSize: 18
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  innerElementsRow: {
    backgroundColor: theme.palette.background.default
  },
  tableCellTitle: {
    fontSize: '1rem',
    maxWidth: 200,
    width: 200,
    whiteSpace: 'nowrap'
  },
  tableCellActions: {
    width: 200,
    whiteSpace: 'nowrap'
  },
  tableCellAuto: {
    maxWidth: 'none',
    width: 'auto'
  },
  tableCell: {
    fontWeight: 700
  },
  tableCellCheckbox: {
    maxWidth: 70,
    width: 70
  },
  statusBadgeRoot: {
    position: 'absolute',
    width: 14,
    height: 14,
    transform: 'translateY(-50%)',
    zIndex: 1,
    cursor: 'pointer',
    top: '50%',
    left: '0'
  },
  statusBadgeDot: {
    width: '100%',
    height: '100%',
    borderRadius: '0 50% 50% 0',
    display: 'block'
  },
  tableHead: {
    backgroundColor: theme.palette.background.title
  },
  innerTable: {
    margin: '.5rem 0',
    backgroundColor: theme.palette.background.default
  },
  innerTableCell: {
    fontWeight: 700,
  },
  innerTableHeaderCell: {
    textTransform: 'uppercase'
  },
  innerTableRow: {
    height: 30,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    },
  },
  innerTableHeaderRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    },
  },
  innerTableLastCell: {
    textAlign: 'right'
  },
  innerTableExpiredCell: {
    backgroundColor: theme.palette.background.coloredRowTimetable
  },
  coloredRow: {
    backgroundColor: theme.palette.background.coloredRow
  }
})

class TableRow extends React.Component {
  state = {
    showInnerElements: false
  }

  parseBadge = (element) => {
    try {
      return element.badge ? JSON.parse(element.badge) : null
    } catch (e) {
      console.error('Error parsing badge:', e)

      return null
    }
  }

  getStatusBadgeColor = (type) => {
    const { theme } = this.props
    
    switch(type || 'error') {
      case 'error': return theme.palette.error.main
      case 'warning': return theme.palette.warning.main
      case 'success': return theme.palette.success.main
      case 'info': return theme.palette.info.main
      default: return theme.palette.error.main
    }
  }

  renderStatusBadge = (badge) => {
    const { classes: currentClasses } = this.props
    
    if (!badge) return null

    return (
      <Tooltip 
        title={badge.tooltip || ''} 
        placement="left"
      >
        <div className={currentClasses.statusBadgeRoot}>
          <span 
            className={currentClasses.statusBadgeDot} 
            style={{ backgroundColor: this.getStatusBadgeColor(badge.type) }} 
          />
        </div>
      </Tooltip>
    )
  }

  render() {
    const { element, onChange, activeBulk, isChecked, classes: currentClasses, rowClasses: classes, tableHead = {}, tableActions, onClickAction, appConfig } = this.props

    const { showInnerElements } = this.state

    const badge = this.parseBadge(element)

    let colSpan = tableHead.length || {}

    if (tableActions && tableActions.length) {
      colSpan++
    }

    if (activeBulk) {
      colSpan++
    }

    const innerElements = element.innerElements ? element.innerElements.data || [element.innerElements] : []

    return (
      <React.Fragment>
        <MUITableRow className={element.isColoredRow ? currentClasses.coloredRow : ''} hover role='checkbox' tabIndex={-1} selected={false} aria-checked='false' >
          {activeBulk ? (
            <TableCell className={classes.tableCellCheckbox} padding='checkbox'>
              {this.renderStatusBadge(badge)}

              <Checkbox checked={isChecked} onChange={onChange} />
            </TableCell>
          ) : null}
          {tableHead.map((item, itmeIndex) => {
            const icon = {
              on: 'search',
              off: 'close'
            }

            if (element.innerElements && element.innerElements.icons) {
              icon.on = element.innerElements.icons.on || icon.on

              icon.off = element.innerElements.icons.off || icon.off
            }

            const cellContent = element[item.field]

            return (
              <TableCell key={itmeIndex} padding='checkbox' className={classes.tableCell}>
                <div className={currentClasses.dFlex}>
                  {element.innerElements && itmeIndex === 0 ? (
                    <IconButton size='small' onClick={() => {
                      return this.setState({
                        showInnerElements: !showInnerElements
                      })
                    }}>
                      <MUIIcon className={classes.innerElements}>{showInnerElements ? icon.off : icon.on}</MUIIcon>
                    </IconButton>
                  ) : null}
                  {(typeof cellContent === 'boolean') ? (
                    (item.optionsStyle ? (
                      <Icon far={(item.optionsStyle[cellContent] || {}).far} color={(item.optionsStyle[cellContent] || {}).color}>{(item.optionsStyle[cellContent] || {}).icon || ( cellContent ? 'check-circle' : 'circle')}</Icon>
                    ) : (
                      <Icon far={true}>{cellContent ? 'check-circle' : 'circle'}</Icon>
                    ))
                  ) : typeof cellContent === 'object' ? (
                    <Button component={cellContent && cellContent.url ? NavLink : 'div'} to={appConfig.appPrefix + (cellContent || {}).url || ''}>{(cellContent || {}).label || ''}</Button>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: cellContent }}></div>
                  )}
                </div>
              </TableCell>
            )
          })}
          {tableActions && tableActions.length ? (
            <TableCell className={classes.tableCellActions} padding='checkbox' >
              <TableActions actions={tableActions} item={element} itemId={element.id} onClickAction={onClickAction} />
            </TableCell>
          ) : null}
        </MUITableRow>
        {showInnerElements ? (
          <MUITableRow>
            <TableCell colSpan={colSpan} className={currentClasses.innerElementsRow}>
              {innerElements.map((innerElement, index) => {
                return (
                  <Table className={currentClasses.innerTable} key={index}>
                    <TableHead>
                      <MUITableRow className={currentClasses.innerTableRow + ' ' + currentClasses.innerTableHeaderRow}>
                        {innerElement.header.map((headerElement, index) => {
                          let isLast = index === innerElement.header.length - 1

                          return (
                            <TableCell key={index} className={currentClasses.innerTableCell + ' ' + currentClasses.innerTableHeaderCell + (isLast ? ' ' + currentClasses.innerTableLastCell : '')}>
                              {headerElement}
                            </TableCell>
                          )
                        })}
                      </MUITableRow>
                    </TableHead>
                    <TableBody>
                      {innerElement.body.map((innerBodyElement, bodyIndex) => {
                        const isColoredRow = !(!innerBodyElement[innerElement.header.length])

                        return (
                          <MUITableRow key={bodyIndex} className={currentClasses.innerTableRow + (isColoredRow ? ' ' + currentClasses.coloredRow : '')}>
                            {innerElement.header.map((innerHeaderElement, headerIndex) => {
                              let isLast = headerIndex === innerElement.header.length - 1

                              const expired = innerBodyElement[innerElement.header.length] ? true : false

                              const innerBodyField = innerBodyElement.fields ? innerBodyElement.fields[headerIndex] : innerBodyElement[headerIndex]

                              if (!innerBodyField && innerBodyElement.actions) {
                                return (
                                  <TableCell key={bodyIndex + '-' + headerIndex} className={currentClasses.innerTableCell + (isLast ? ' ' + currentClasses.innerTableLastCell : '') + (expired ? ' ' + currentClasses.innerTableExpiredCell : '')}>
                                    {
                                      isLast ? <TableActions actions={innerBodyElement.actions} itemId={element.id} onClickAction={onClickAction} /> : ''
                                    }
                                  </TableCell>
                                )
                              }

                              return (
                                <TableCell key={bodyIndex + '-' + headerIndex} className={currentClasses.innerTableCell + (isLast ? ' ' + currentClasses.innerTableLastCell : '') + (expired ? ' ' + currentClasses.innerTableExpiredCell : '')}>
                                  {innerBodyField}
                                </TableCell>
                              )
                            })}
                          </MUITableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                )
              })}
            </TableCell>
          </MUITableRow>
        ) : null}
      </React.Fragment>
    )
  }
}

TableRow.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TableRow)))
