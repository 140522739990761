import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import Description from '@material-ui/icons/Description'
import GetApp from '@material-ui/icons/GetApp'
import Close from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    zIndex: 1400,
    transition: 'all 1s ease-in-out'
  },
  startAnimation: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    borderRadius: '50%',
    border: '5px solid ' + theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[4]
  },
  startAnimationIcon: {
    fontSize: '2rem',
    display: 'block',
    color: theme.palette.primary.contrastText
  },
  closeFab: {
    position: 'absolute',
    top: -5,
    left: -5,
    height: 20,
    width: 20,
    minHeight: 20,
    minWidth: 20,
    maxHeight: 20,
    maxWidth: 20,
    zIndex: 2,
    backgroundColor: '#fff'
  },
  closeButton: {
    fontSize: '14px',
    opacity: 0.8
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '.75rem 1rem',
    position: 'relative',
    zIndex: 1,
    opacity: 0,
    width: 300,
    borderRadius: 5,
    boxShadow: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
    backgroundColor: theme.palette.background.default,
    marginTop: '.5rem',
  },
  cardAnimation: {
    opacity: 1,
    animation: 'card-animation 1500ms linear both'
  },
  cardAction: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '2.5rem',
    maxWidth: '2.5rem',
    minHeight: '2.5rem',
    maxHeight: '2.5rem'
  },
  cardBody: {
    flexGrow: 1,
    paddingRight: '.5rem',
    overflow: 'hidden'
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    overflowWrap: 'break-word'
  },
  cardInfo: {
    fontSize: '.875rem',
    color: theme.palette.grey[600]
  },
  textCreation: {
    display: 'flex',
    overflow: 'hidden',
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
    minHeight: '1.5rem',
    maxHeight: '1.5rem',
    borderRadius: '50%',
    color: 'rgb(66, 139, 202)',
    position: 'relative'
  },
  icon: {
    display: 'block',
    width: 24,
    height: 24,
  },
  spinner: {
    position: 'absolute',
    minWidth: '2.5rem',
    maxWidth: '2.5rem',
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
    opacity: '.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCreationFile: {
    width: '1.5rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCreationFileIcon: {
    position: 'relative',
    zIndex: 1,
    opacity: '.4'
  },
  textCreationFileIconActive: {
    position: 'absolute',
    top: 0,
    zIndex: 2,
    opacity: 1,
    overflow: 'hidden',
    height: 0,
    animation: 'file-animation-icon 1s infinite'
  },
  textCreationNewFile: {
    width: '1.5rem',
    marginLeft: '-1.5rem',
    animation: 'file-animation 1s infinite',
    opacity: '.4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inProgress: {
    '&:after': {
      content: '""',
      animation: 'in-progress-animation 1s infinite',
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  waiting: {
    animation: 'waiting-animation 3s linear infinite',
    opacity: '.5'
  },
  resetZoom: {
    zoom: '123.33%'
  },
  '@keyframes waiting-animation': {
    '0%': {
      transform: 'rotate(0)'
    },
    '5%': {
      transform: 'rotate(0)'
    },
    '20%': {
      transform: 'rotate(110deg)'
    },
    '23%': {
      transform: 'rotate(80deg)'
    },
    '25%': {
      transform: 'rotate(90deg)'
    },
    '30%': {
      transform: 'rotate(90deg)'
    },
    '45%': {
      transform: 'rotate(200deg)'
    },
    '48%': {
      transform: 'rotate(170deg)'
    },
    '50%': {
      transform: 'rotate(180deg)'
    },
    '55%': {
      transform: 'rotate(180deg)'
    },
    '70%': {
      transform: 'rotate(290deg)'
    },
    '73%': {
      transform: 'rotate(260deg)'
    },
    '75%': {
      transform: 'rotate(270deg)'
    },
    '80%': {
      transform: 'rotate(270deg)'
    },
    '95%': {
      transform: 'rotate(380deg)'
    },
    '98%': {
      transform: 'rotate(350deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes file-animation-icon': {
    from: {
      height: 0
    },
    to: {
      height: '1.5rem'
    }
  },
  '@keyframes file-animation': {
    '0%': {
      marginLeft: '-1.5rem'
    },
    '50%': {
      marginLeft: '-1.5rem'
    },
    '100%': {
      marginLeft: 0
    }
  },
  // Animazione ingresso card
  '@keyframes card-animation': {
    '0%': {
      transform: 'matrix3d(0.5, 0.289, 0, 0, 0.182, 0.5, 0, 0, 0, 0, 1, 0, -175, 0, 0, 1)'
    },
    '2.2%': {
      transform: 'matrix3d(0.684, 0.192, 0, 0, 0.126, 0.684, 0, 0, 0, 0, 1, 0, -164.834, 0, 0, 1)'
    },
    '4.27%': {
      transform: 'matrix3d(0.942, 0.059, 0, 0, 0.039, 0.942, 0, 0, 0, 0, 1, 0, -126.011, 0, 0, 1)'
    },
    '4.4%': {
      transform: 'matrix3d(0.958, 0.049, 0, 0, 0.033, 0.958, 0, 0, 0, 0, 1, 0, -121.972, 0, 0, 1)'
    },
    '6.41%': {
      transform: 'matrix3d(1.159, -0.072, 0, 0, -0.048, 1.159, 0, 0, 0, 0, 1, 0, -48.239, 0, 0, 1)'
    },
    '6.61%': {
      transform: 'matrix3d(1.173, -0.08, 0, 0, -0.054, 1.173, 0, 0, 0, 0, 1, 0, -40.374, 0, 0, 1)'
    },
    '8.54%': {
      transform: 'matrix3d(1.248, -0.119, 0, 0, -0.079, 1.248, 0, 0, 0, 0, 1, 0, 26.632, 0, 0, 1)'
    },
    '8.81%': {
      transform: 'matrix3d(1.249, -0.118, 0, 0, -0.079, 1.249, 0, 0, 0, 0, 1, 0, 33.79, 0, 0, 1)'
    },
    '9.61%': {
      transform: 'matrix3d(1.241, -0.11, 0, 0, -0.073, 1.241, 0, 0, 0, 0, 1, 0, 51.409, 0, 0, 1)'
    },
    '11.21%': {
      transform: 'matrix3d(1.181, -0.076, 0, 0, -0.051, 1.181, 0, 0, 0, 0, 1, 0, 69.46, 0, 0, 1)'
    },
    '12.81%': {
      transform: 'matrix3d(1.088, -0.039, 0, 0, -0.026, 1.088, 0, 0, 0, 0, 1, 0, 69.206, 0, 0, 1)'
    },
    '13.61%': {
      transform: 'matrix3d(1.039, -0.023, 0, 0, -0.016, 1.039, 0, 0, 0, 0, 1, 0, 64.796, 0, 0, 1)'
    },
    '13.68%': {
      transform: 'matrix3d(1.035, -0.022, 0, 0, -0.015, 1.035, 0, 0, 0, 0, 1, 0, 64.348, 0, 0, 1)'
    },
    '16.02%': {
      transform: 'matrix3d(0.92, 0.005, 0, 0, 0.003, 0.92, 0, 0, 0, 0, 1, 0, 45.399, 0, 0, 1)'
    },
    '18.42%': {
      transform: 'matrix3d(0.879, 0.013, 0, 0, 0.008, 0.879, 0, 0, 0, 0, 1, 0, 26.586, 0, 0, 1)'
    },
    '18.75%': {
      transform: 'matrix3d(0.88, 0.013, 0, 0, 0.009, 0.88, 0, 0, 0, 0, 1, 0, 24.256, 0, 0, 1)'
    },
    '20.52%': {
      transform: 'matrix3d(0.907, 0.011, 0, 0, 0.007, 0.907, 0, 0, 0, 0, 1, 0, 13.002, 0, 0, 1)'
    },
    '20.82%': {
      transform: 'matrix3d(0.914, 0.011, 0, 0, 0.007, 0.914, 0, 0, 0, 0, 1, 0, 11.266, 0, 0, 1)'
    },
    '23.22%': {
      transform: 'matrix3d(0.984, 0.005, 0, 0, 0.003, 0.984, 0, 0, 0, 0, 1, 0, -0.608, 0, 0, 1)'
    },
    '25.63%': {
      transform: 'matrix3d(1.04, 0, 0, 0, 0, 1.04, 0, 0, 0, 0, 1, 0, -7.985, 0, 0, 1)'
    },
    '27.93%': {
      transform: 'matrix3d(1.059, -0.002, 0, 0, -0.001, 1.059, 0, 0, 0, 0, 1, 0, -10.324, 0, 0, 1)'
    },
    '28.13%': {
      transform: 'matrix3d(1.058, -0.002, 0, 0, -0.001, 1.058, 0, 0, 0, 0, 1, 0, -10.335, 0, 0, 1)'
    },
    '29.03%': {
      transform: 'matrix3d(1.054, -0.002, 0, 0, -0.002, 1.054, 0, 0, 0, 0, 1, 0, -10.081, 0, 0, 1)'
    },
    '32.73%': {
      transform: 'matrix3d(1.008, -0.001, 0, 0, -0.001, 1.008, 0, 0, 0, 0, 1, 0, -6.029, 0, 0, 1)'
    },
    '37.44%': {
      transform: 'matrix3d(0.972, 0, 0, 0, 0, 0.972, 0, 0, 0, 0, 1, 0, -0.739, 0, 0, 1)'
    },
    '39.31%': {
      transform: 'matrix3d(0.977, 0, 0, 0, 0, 0.977, 0, 0, 0, 0, 1, 0, 0.489, 0, 0, 1)'
    },
    '43.54%': {
      transform: 'matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 1.505, 0, 0, 1)'
    },
    '46.95%': {
      transform: 'matrix3d(1.014, 0, 0, 0, 0, 1.014, 0, 0, 0, 0, 1, 0, 1.151, 0, 0, 1)'
    },
    '49.58%': {
      transform: 'matrix3d(1.009, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0.644, 0, 0, 1)'
    },
    '56.56%': {
      transform: 'matrix3d(0.993, 0, 0, 0, 0, 0.993, 0, 0, 0, 0, 1, 0, -0.186, 0, 0, 1)'
    },
    '58.96%': {
      transform: 'matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, -0.229, 0, 0, 1)'
    },
    '59.86%': {
      transform: 'matrix3d(0.997, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, -0.224, 0, 0, 1)'
    },
    '66.07%': {
      transform: 'matrix3d(1.003, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, -0.068, 0, 0, 1)'
    },
    '66.67%': {
      transform: 'matrix3d(1.003, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, -0.052, 0, 0, 1)'
    },
    '74.37%': {
      transform: 'matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0.035, 0, 0, 1)'
    },
    '75.58%': {
      transform: 'matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0.034, 0, 0, 1)'
    },
    '85.09%': {
      transform: 'matrix3d(1.001, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1)'
    },
    '89.79%': {
      transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.005, 0, 0, 1)'
    },
    '94.69%': {
      transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.003, 0, 0, 1)'
    },
    '100%': {
      transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)'
    },
  },
  '@keyframes in-progress-animation': {
    '0%': {
      content: '""'
    },
    '33%': {
      content: '"."'
    },
    '66%': {
      content: '".."'
    },
    '100%': {
      content: '"..."'
    }
  }
})

class Print extends React.Component {
  state = {
    startAnimation: false,
    fromInit: false
  }

  componentDidUpdate = (prevProps) => {
    const {
      fromInit: stateFromInit
    } = this.state

    const {
      fromInit,
      currentPrintItem
    } = this.props

    const { fromInit: prevFromInit } = prevProps

    if ((!prevFromInit && fromInit) || !stateFromInit) {
      this.setState({
        fromInit: true
      }, () => {
        this.props.onPrintStatus((currentPrintItem || {}).params || {})
      })
    }
  }

  getCardStatusInfo = item => {
    const {
      classes
    } = this.props

    switch (item.status) {
      case 'waiting':
        return (
          <div className={classes.inProgress}>{item.labels.waiting}</div>
        )
      case 'progress':
        return (
          <div className={classes.inProgress}>{item.labels.inProgress}</div>
        )
      case 'error':
        return (
          <div>{item.message}</div>
        )
      default:
        return (
          <div>{item.labels.done}</div>
        )
    }
  }

  getCardAction = item => {
    const {
      classes,
      appConfig = {}
    } = this.props

    switch (item.status) {
      case 'progress':
        return (
          <div className={classes.cardAction}>
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
            <div className={classes.textCreation}>
              <div className={classes.textCreationNewFile}>
                <InsertDriveFileOutlined classes={{ root: classes.icon }} />
              </div>
              <div className={classes.textCreationFile}>
                <div className={classes.textCreationFileIcon}>
                  <InsertDriveFileOutlined classes={{ root: classes.icon }} />
                </div>
                <div className={[
                  classes.textCreationFileIcon,
                  classes.textCreationFileIconActive
                ].join(' ')}>
                  <Description classes={{ root: classes.icon }} />
                </div>
              </div>
            </div>
          </div>
        )
      case 'waiting':
        return (
          <div className={classes.cardAction}>
            <div className={classes.waiting}>
              <HourglassEmpty classes={{ root: classes.icon }} />
            </div>
          </div>
        )
      case 'error':
        return (
          <div className={classes.cardAction}>
            <Fab size='small' color='secondary' rel='noopener noreferre' onClick={() => {
              this.props.onPrintStatusRemove(item.params)
            }}>
              <Close />
            </Fab>
          </div>
        )
      default:
        return (
          <div className={classes.cardAction}>
            <Fab size='small' color='primary' component='a' href={appConfig.apiUrl + item.pdf} rel='noopener noreferre' target='_blank' onClick={() => {
              setTimeout(() => {
                this.props.onPrintStatusRemove(item.params)

                this.props.onPrintCancel(item.params)
              }, 1000)
            }}>
              <GetApp />
            </Fab>
          </div>
        )
    }
  }

  render() {
    const {
      classes,
      printsStack
    } = this.props

    if (!printsStack.length) {
      return ''
    }

    return (
      <div className={classes.root}>
        {printsStack.length ? (
          <div className={classes.cards}>{printsStack.filter(item => {
            return !(!(item || {}).labels)
          }).map((item = {}, index) => {
            const {
              progress = 100
            } = item

            return (
              <div className={[
                classes.card,
                classes.cardAnimation
              ].join(' ')} key={index}>
                <div className={classes.resetZoom}>
                  <Fab className={classes.closeFab} onClick={() => { this.props.onPrintCancel(item.params) }}>
                    <Close className={classes.closeButton} />
                  </Fab>
                </div>
                {item.status !== 'waiting' ? (
                  <div className={classes.progress}>
                    <LinearProgress variant='determinate' color={item.status === 'error' ? 'secondary' : 'primary'} value={item.status === 'error' ? 100 : progress} />
                  </div>
                ) : ''}
                <div className={classes.cardBody}>
                  <div className={classes.cardTitle}>
                    {item.labels.name}
                  </div>
                  <div className={classes.cardInfo}>
                    {this.getCardStatusInfo(item)}
                  </div>
                </div>
                {this.getCardAction(item)}
              </div>
            )
          })}
          </div>
        ) : ''}
      </div>
    )
  }
}

Print.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.printReducer, ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintClose: () => {
    dispatch(printActions.printClose())
  },
  onPrintStatus: params => {
    dispatch(printActions.printStatusGet(params))
  },
  onPrintStatusRemove: params => {
    dispatch(printActions.printStatusRemove(params))
  },
  onPrintCancel: params => {
    dispatch(printActions.printCancel(params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Print)))
