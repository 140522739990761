import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ModalComponent from '../../ModalComponent'
import TextField from '@material-ui/core/TextField'
import Date from '../../PageActions/InlineForm/Date'
import Time from '../../PageActions/InlineForm/Time'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AsyncSelect from '../../PageActions/InlineForm/AsyncSelect'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Worker from './Worker'
import Select from '../../PageActions/InlineForm/Select'
import _ from 'lodash'
import moment from 'moment'

import {
  agendaActions
} from '../../../actions'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  dateRoot: {
    height: 67
  },
  dateInput: {
    marginTop: 16,
    marginBottom: 8
  },
  inputField: {
    padding: '18.5px 14px'
  },
  dateLabel: {
    transform: 'translate(14px, 20px) !important',
    fontWeight: 400,
    color: theme.palette.text.default,
    textTransform: 'none'
  },
  dateLabelShrink: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -7px)  scale(0.75) !important'
  },
  checkbox: {
    marginBottom: '1rem'
  },
  formActions: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  firstFormAction: {
    marginRight: '.5rem'
  },
  buttonPrimary: {
    backgroundColor: '#38bf60',
    '&:hover': {
      backgroundColor: '#3f9258',
    }
  },
  selectLabel: {
    top: 0
  },
  workers: {
  },
  divider: {
    margin: '2rem 0 1rem',
    borderTop: '1px solid' + theme.palette.border.table,
    borderBottom: 0
  },
  titleBlock: {
    marginBottom: '2rem',
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  inlineBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0
  },
  inlineInput: {
    flexGrow: 1,
  },
  inlineLabel: {
    fontWeight: 700
  },
  inlineInputInput: {
    padding: '0 14px',
    height: 42
  },
  inlineInputRoot: {
    margin: '0 .25rem'
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  timeLabel: {
    fontSize: '14px',
    marginRight: '5px'
  }
})

class Form extends React.Component {
  state = {
    workers: [],
    eventConfirmed: false
  }

  componentDidMount = () => {
    const {
      agendaConfig,
    } = this.props

    this.setState({
      slot: agendaConfig.slot,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      activeEvent,
      day,
      errors = [],
      openForm,
      closeForm,
      onClose
    } = this.props

    const {
      activeEvent: prevActiveEvent,
      day: prevDay,
      errors: prevErrors = [],
      openForm: prevOpenForm,
      closeForm: prevCloseForm
    } = prevProps

    if (closeForm && !prevCloseForm) {
      onClose(activeEvent)
    }

    if ((!prevDay && day) || (prevDay && prevDay !== day)) {
      this.setState({
        selectValue: {
          ...(this.state.selectValue || {}),
          date: day ? day.format(window.appConfig.dateFormat) : null,
        },
        companyObj: (activeEvent || {}).company || null
      })
    }

    if (openForm && !prevOpenForm) {
      if (activeEvent) {
        this.setState({
          workers: (activeEvent.workers || []).map(worker => {
            return {
              key: worker.id,
              doctor: worker.doctor.id,
              time: worker.time || '',
              worker: worker.worker.id,
              __path: worker.__path,
              defaultWorker: [
                {
                  label: worker.worker.name,
                  value: worker.worker.id
                }
              ]
            }
          })
        })
      } else {
        this.setState({
          companyChecked: false,
          doctorChecked: false,
          selectValue: null,
          workers: [],
          eventConfirmed: false
        })
      }
    }

    if ((!prevErrors.length && errors.length) || (prevErrors.length && !errors.length)) {
      this.setState({
        errors
      })
    }

    if (!prevActiveEvent && activeEvent) {
      this.setState({
        companyChecked: !(!(activeEvent || {}).company),
        doctorChecked: !(!(activeEvent || {}).doctor),
        selectValue: {
          ...activeEvent,
          company: activeEvent.company ? activeEvent.company.id : null
        },
        companyObj: activeEvent.company || null,
        eventConfirmed: activeEvent.evento_confermato
      })
    } else if (prevActiveEvent && !activeEvent) {
      this.setState({
        companyChecked: false,
        doctorChecked: false,
        selectValue: null,
        workers: []
      })
    }
  }

  onSaveEvent = () => {
    const {
      current,
      type,
      day,
      time,
      endTime
    } = this.props

    const {
      workers
    } = this.state

    let {
      selectValue = {}
    } = this.state

    selectValue = selectValue || {}

    const fields = [
      {
        name: 'titolo',
        value: selectValue.title
      },
      {
        name: 'descrizione',
        value: selectValue.description
      },
      {
        name: 'note',
        value: selectValue.notes
      },
      {
        name: 'id_medico',
        value: selectValue.doctor
      },
      {
        name: 'id_azienda',
        value: selectValue.company || null
      },
      {
        name: 'data',
        value: selectValue.date || day.format(window.appConfig.dateFormat)
      },
      {
        name: 'data_fine',
        value: selectValue.date || day.format(window.appConfig.dateFormat)
      },
      {
        name: 'workers',
        value: workers
      },
      {
        name: 'evento_confermato',
        value: selectValue.evento_confermato
      }
    ]

    if (selectValue.time || time) {
      fields.push({
        name: 'data__time',
        value: selectValue.time || time
      })
    }

    if (selectValue.endTime || endTime) {
      fields.push({
        name: 'data_fine__time',
        value: selectValue.endTime || endTime
      })
    }

    this.props.onSaveEvent({
      current,
      id: selectValue.id || null,
      type,
      day: day ? day.format(window.appConfig.dateFormat) : null,
      fields,
      callback: () => {
        this.props.onClose()
      }
    })
  }

  getError = fieldname => {
    const { errors = [] } = this.state

    if (!errors.length) {
      return false
    }

    return errors.find(error => {
      return error.context.key === fieldname
    })
  }

  onDelete = index => {
    const tmpWorkers = _.cloneDeep(this.state.workers)

    tmpWorkers.splice(index, 1)

    this.setState({
      workers: tmpWorkers
    })
  }

  render() {
    const {
      openForm,
      activeEvent,
      day,
      onClose,
      classes,
      companyHelper,
      workerHelper,
      doctors,
      time,
      endTime,
      confirms,
      theme
    } = this.props

    const {
      companyChecked,
      doctorChecked,
      companyObj,
      slot,
      workers,
      eventConfirmed
    } = this.state

    let {
      selectValue = {}
    } = this.state

    selectValue = selectValue || {}

    const emptyWorkers = workers.filter(worker => {
      return !worker.worker
    }).length

    if (!activeEvent && !day) {
      return ''
    }

    const errors = {
      date: this.getError('data'),
      title: this.getError('titolo'),
      description: this.getError('descrizione'),
      notes: this.getError('note'),
      company: this.getError('id_azienda'),
    }

    const options = doctors.map(doctor => {
      return {
        label: doctor.name,
        value: doctor.id
      }
    })

    return (
      <ModalComponent
        open={!(!openForm)}
        onClose={() => onClose()}
        type='lg'
        modalClasses={{
          body: classes.modalBody
        }}
        body={(
          <div className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <Date
                  field={{
                    label: 'Data',
                    forcePosition: true,
                    position: 'bl',
                    value: activeEvent ? activeEvent.date : day.format(window.appConfig.dateFormat),
                    error: (errors.date || {}).message || '',
                    classes: {
                      root: classes.dateRoot,
                      input: classes.dateInput,
                      inputField: classes.inputField,
                      label: classes.dateLabel,
                      labelShrink: classes.dateLabelShrink
                    }
                  }}
                  onChange={selectedDate => {
                    this.setState({
                      selectValue: {
                        ...selectValue,
                        date: selectedDate,
                        date_fine: selectedDate
                      }
                    })
                  }} />
              </Grid>
              <Grid item xs={3}>
                <div className={classes.timeContainer}>
                  <div className={classes.timeLabel}>
                    dalle:
                  </div>
                  <div>
                    <Time
                      field={{
                        value: activeEvent && activeEvent.time ? activeEvent.time : (time || ''),
                        onChange: selectedTime => {
                          this.setState({
                            selectValue: {
                              ...selectValue,
                              time: selectedTime
                            }
                          })
                        }
                      }} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.timeContainer}>
                  <div className={classes.timeLabel}>
                    alle:
                  </div>
                  <div>
                    <Time
                      field={{
                        minTime: selectValue && selectValue.time ? selectValue.time : (time || ''),
                        value: activeEvent && activeEvent.endTime ? activeEvent.endTime : (endTime || ''),
                        onChange: selectedTime => {
                          this.setState({
                            selectValue: {
                              ...selectValue,
                              endTime: selectedTime
                            }
                          })
                        }
                      }} />
                  </div>
                </div>
              </Grid>
            </Grid>
            <TextField
              margin='normal'
              variant='outlined'
              defaultValue={(activeEvent || {}).title || ''}
              onChange={e => {
                this.setState({
                  selectValue: {
                    ...selectValue,
                    title: e.target.value
                  }
                })
              }}
              error={!(!errors.title)}
              helperText={(errors.title || {}).message || ''}
              label='Titolo' />
            <TextField
              margin='normal'
              variant='outlined'
              defaultValue={(activeEvent || {}).description || ''}
              onChange={e => {
                this.setState({
                  selectValue: {
                    ...selectValue,
                    description: e.target.value
                  }
                })
              }}
              multiline={true}
              rows={6}
              error={!(!errors.description)}
              helperText={(errors.description || {}).message || ''}
              label='Descrizione' />
            <TextField
              margin='normal'
              variant='outlined'
              defaultValue={(activeEvent || {}).notes || ''}
              onChange={e => {
                this.setState({
                  selectValue: {
                    ...selectValue,
                    notes: e.target.value
                  }
                })
              }}
              multiline={true}
              rows={6}
              error={!(!errors.notes)}
              helperText={(errors.notes || {}).message || ''}
              label='Note' />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Switch
                  checked={!(!doctorChecked)}
                  value='checkedA'
                  color='primary'
                  disabled={!(!(activeEvent || {}).doctor) || companyChecked}
                  onChange={() => {
                    this.setState({
                      doctorChecked: !doctorChecked
                    }, () => {
                      this.setState({
                        selectValue: {
                          ...selectValue,
                          doctor: !this.state.doctorChecked ? null : selectValue.doctor || ((activeEvent || {}).doctor || {}).id
                        }
                      })
                    })
                  }} />
              }
              label='Aggiungi medico' />
            {doctorChecked ? (
              <React.Fragment>
                <Select
                  onChange={selectedOption => {
                    this.setState({
                      selectValue: {
                        ...selectValue,
                        doctor: selectedOption
                      }
                    })
                  }}
                  field={{
                    selectProps: {
                      isDisabled: !(!(activeEvent || {}).doctor) || companyChecked
                    },
                    options,
                    label: 'Medico',
                    value: (activeEvent || {}).doctor ? options.find(option => {
                      return option.value === (activeEvent || {}).doctor
                    }) : null,
                    focused: false,
                    classes: {
                      label: classes.selectLabel,
                      root: (provided) => ({
                        ...provided,
                        marginBottom: 0
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        borderStyle: 'solid',
                        borderWidth: state.isFocused ? 2 : 1,
                        borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
                        borderRadius: 4,
                        backgroundColor: 'transparent',
                        margin: 0,
                        minHeight: 54,
                        padding: '0 14px',
                        boxShadow: 'none',
                        '&:hover': {
                          borderColor: theme.palette.select.inputControlBorder,
                        }
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        backgroundColor: theme.palette.select.inputControlMenuListBackground,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 100,
                        top: 'auto',
                        bottom: '100%'
                      }),
                    }
                  }} />
                <hr className={classes.divider} />
              </React.Fragment>
            ) : ''}
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Switch
                  checked={!(!companyChecked)}
                  value='checkedB'
                  color='primary'
                  disabled={!(!(activeEvent || {}).company) || doctorChecked}
                  onChange={() => {
                    this.setState({
                      companyChecked: !companyChecked
                    }, () => {
                      this.setState({
                        selectValue: {
                          ...selectValue,
                          company: !this.state.companyChecked ? null : selectValue.company || ((activeEvent || {}).company || {}).id
                        }
                      })
                    })
                  }} />
              }
              label='Aggiungi azienda' />
            {companyChecked ? (
              <React.Fragment>
                <AsyncSelect
                  onChange={(selectedOption, index, name, companyObj) => {
                    this.setState({
                      selectValue: {
                        ...selectValue,
                        company: selectedOption,
                      },
                      workers: [],
                      companyObj: {
                        id: companyObj.value,
                        name: companyObj.label,
                        doctor: companyObj.forRelated.id_medico_incaricato
                      }
                    })
                  }}
                  field={{
                    selectProps: {
                      isDisabled: !(!(activeEvent || {}).company) || doctorChecked
                    },
                    options: companyHelper,
                    label: 'Azienda',
                    value: (activeEvent || {}).company ? {
                      label: [
                        activeEvent.company.name,
                        activeEvent.company.address
                      ].join(' - ')
                    } : null,
                    focused: !(!(activeEvent || {}).company),
                    error: (errors.company || {}).message || '',
                    classes: {
                      label: classes.selectLabel,
                      root: (provided) => ({
                        ...provided,
                        marginBottom: 0
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        borderStyle: 'solid',
                        borderWidth: state.isFocused ? 2 : 1,
                        borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
                        borderRadius: 4,
                        backgroundColor: 'transparent',
                        margin: 0,
                        minHeight: 54,
                        padding: '0 14px',
                        boxShadow: 'none',
                        '&:hover': {
                          borderColor: theme.palette.select.inputControlBorder,
                        }
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        backgroundColor: theme.palette.select.inputControlMenuListBackground,
                      }),
                      menu: (provided) => ({
                        backgroundColor: theme.palette.select.inputControlMenuListBackground,
                        ...provided,
                        zIndex: 100,
                        top: 'auto',
                        bottom: '100%'
                      }),
                    }
                  }} />
                {(activeEvent || {}).company || selectValue.company ? (
                  <div className={classes.workers}>
                    <hr className={classes.divider} />
                    <Grid container spacing={24} className={classes.titleBlock}>
                      <Grid item xs={8}>
                        <div className={classes.title}>Lavoratori da convocare</div>
                      </Grid>
                      <Grid item xs={4} className={classes.inlineBlock}>
                        <div className={classes.inlineLabel}>
                          Ogni:
                        </div>
                        <div className={classes.inlineInput}>
                          <TextField
                            margin='normal'
                            variant='outlined'
                            type='number'
                            value={slot}
                            onChange={(e => {
                              this.setState({
                                slot: e.target.value
                              })
                            })}
                            classes={{
                              root: classes.inlineInputRoot
                            }}
                            inputProps={{
                              className: classes.inlineInputInput
                            }} />
                        </div>
                        <div className={classes.inlineLabel}>minuti</div>
                      </Grid>
                    </Grid>
                    {workers.map((worker, index) => {
                      const tmpTime = selectValue.time || time || ''

                      if (tmpTime && !worker.time) {
                        worker.time = index ? workers[index - 1].time || tmpTime : tmpTime
                      }

                      return (
                        <Worker key={worker.key} prevWorkers={workers.filter(item => {
                          return !(!item.worker) && worker.worker !== item.worker
                        }).map(item => {
                          return item.worker
                        })} onChange={(worker, index) => {
                          const tmpWorkers = _.cloneDeep(workers)

                          tmpWorkers[index] = _.cloneDeep(worker)

                          this.setState({
                            workers: tmpWorkers,
                            selectValue: {
                              ...this.state.selectValue,
                              workers: tmpWorkers
                            }
                          })
                        }} {...{
                          doctors,
                          workerHelper,
                          companyObj,
                          index,
                          confirms,
                          field: worker,
                          onDelete: this.onDelete
                        }} />
                      )
                    })}
                    {emptyWorkers ? '' : (
                      <Button variant='contained' onClick={() => {
                        const tmpWorkers = _.cloneDeep(workers)

                        const index = tmpWorkers.length

                        const currentActiveItem = selectValue || activeEvent

                        const prevTime = index ? tmpWorkers[index - 1].time : (currentActiveItem && currentActiveItem.time ? currentActiveItem.time : (time || ''))

                        const tmpTime = moment([
                          moment().format('YYYY-MM-DD'),
                          prevTime
                        ].join(' '), 'YYYY-MM-DD HH:mm')

                        tmpWorkers.push({
                          key: _.uniqueId('worker_id_'),
                          isNew: true,
                          doctor: companyObj.doctor,
                          time: prevTime ? (index ? tmpTime.add(slot, 'minutes') : tmpTime).format('HH:mm') : ''
                        })

                        this.setState({
                          workers: tmpWorkers
                        })
                      }} className={classes.firstFormAction}>Aggiungi lavoratore</Button>
                    )}
                  </div>
                ) : ''}
              </React.Fragment>
            ) : ''}
          </div>
        )}
        footer={(
          <div className={classes.formActions}>
            <FormGroup>
              <FormControlLabel 
                label="Confermato"
                control={
                  <Checkbox 
                    color='primary'
                    checked={!(!eventConfirmed)}
                    onChange={() => {
                      this.setState({
                        eventConfirmed: !this.state.eventConfirmed
                      }, () => {
                        this.setState({
                          selectValue: {
                            ...selectValue,
                            evento_confermato: this.state.eventConfirmed
                          }
                        })
                      })
                    }}
                  />
                } 
              />
            </FormGroup>
            <div>
              <Button variant='contained' onClick={() => onClose()} className={classes.firstFormAction}>
                Annulla
              </Button>
              <Button variant='contained' onClick={params => {
                this.onSaveEvent(params)
              }} color='primary' className={classes.buttonPrimary}>
                Salva
              </Button>
            </div>
          </div>
        )} />
    )
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onSaveEvent: params => {
    dispatch(agendaActions.agendaSave(params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Form)))
