import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUIRadio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import _ from 'lodash'
import LabelWithAsterisk from './LabelWithAsterisk'

import {} from '../../actions'

const styles = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontWeight: 600,
    fontSize: '0.9285714285714286rem',
    textTransform: 'uppercase'
  },
  radioLabel: {
    fontSize: '1rem',
  },
  break: {
    flexBasis: '100%',
  }
})

class Radio extends React.Component {
  state = {}

  componentDidMount = () => {
    const { value, options } = this.props.field

    let currentValue = value

    if (_.isObject(value)) {
      currentValue = value.value
    } else if (typeof value === 'undefined' || value === null || value === '') {
      currentValue = options[0].value
    }

    this.setState({
      currentValue
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentValue } = this.state

    const { currentValue: preCurrentValue } = prevState

    const fromState = ('' + currentValue) !== ('' + preCurrentValue)

    let { value: prevValue } = prevProps.field

    const { field, onChange } = this.props

    let { value, options } = field

    if (_.isObject(prevValue)) {
      prevValue = prevValue.value
    } else if (typeof prevValue === 'undefined' || prevValue === null || prevValue === '') {
      prevValue = options[0].value
    }

    if (_.isObject(value)) {
      value = value.value
    } else if (typeof value === 'undefined' || value === null || value === '') {
      value = options[0].value
    }

    const valueVsPrevValue = ('' + value) !== ('' + prevValue)

    const valueVsCurrentValue = ('' + value) !== ('' + currentValue)

    if (!fromState && (valueVsPrevValue || valueVsCurrentValue)) {
      return this.setState({
        currentValue: value
      }, () => {
        onChange({ type: 'radio' }, field, value)
      })
    }
  }

  render() {
    const { classes, field, onChange } = this.props

    const { label, options, name } = field

    const { currentValue } = this.state

    return (
      <div className={classes.root}>
        {label ? (
          <FormLabel className={classes.label} component='legend'><LabelWithAsterisk>{label}</LabelWithAsterisk></FormLabel>
        ) : null}
        <RadioGroup
          aria-label={label || ''}
          name={name}
          className={classes.group}
          value={'' + currentValue}
          onChange={(event) => {
            const value = event.target.value

            this.setState({
              currentValue: value
            })

            const optionsValue = options.filter(option => {
              return ('' + option.value) === value
            })[0]

            return onChange({type: 'radio'}, field, optionsValue)
          }} >
          {options.map((option, index) => {
            return (
              <FormControlLabel key={index} classes={{
                root: option.fullWidth ? classes.break : '',
                label: classes.radioLabel
              }} value={'' + option.value} control={<MUIRadio />} label={option.label} />
            )
          })}
        </RadioGroup>
      </div>
    )
  }
}

Radio.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Radio)))
