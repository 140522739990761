import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from './Checkbox'
import Select from './Select'
import Button from '@material-ui/core/Button'
import Icon from '../../Icon'

import {
  formActions,
  blockActions,
  messageActions
} from '../../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: '1rem'
  },
  item: {
    flexGrow: 1,
  },
  button: {
    whiteSpace: 'nowrap'
  },
  divider: {
    minWidth: '100%',
    maxWidth: '100%',
    margin: '1rem 0',
    borderBottom: '1px solid' + theme.palette.border.table,
  },
  btnContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.5rem'
  }
})

const actions = {
  checkbox: Checkbox,
  select: Select,
}

class TopActions extends React.Component {
  render() {
    const { topActions, classes, parent, match, theme } = this.props
    
    return (
      <div className={classes.root}>
        {topActions.map((action, index) => {
          const { label, style = {} } = action

          const { icon } = style

          const TmpAction = actions[action.type] || null

          if (action.type === 'divider') {
            return (
              <div key={index} className={classes.divider} />
            )
          }

          const getButtonStyle = (button, theme) => {
            const buttonType = button.buttonType || 'default'  

            return {
              ...theme.palette.buttonStyles[buttonType] || theme.palette.buttonStyles.default,
              ...(button.style || {}),
              ...(button.styles || {})
            }
          }

          if (!TmpAction) {
            let onClick = null

            switch (action.actionType) {
              case 'simpleCall':
                onClick = () => this.props.onAjaxCall(action.url, match.params, data => {
                  const { status, message } =  data

                  if (action.updateRelated) {
                    this.props.onShowMessage({
                      message: action.updateRelated[status],
                      type: status,
                      autoHide: true
                    })

                    this.props.onUpdateRelated(data.data)
                  } else if (message) {
                    this.props.onShowMessage({
                      message,
                      type: status,
                      autoHide: true
                    })
                  }
                })

                break
              default:
                onClick = () => parent.onActionClick(action.action)

                break
            }
            return (
              <Button key={index} className={[classes.button, classes.item].join(' ')} style={getButtonStyle(action, theme)}onClick={onClick}>
                <div className={classes.btnContent}>                  
                  <Icon className={classes.icon}>{icon}
                  </Icon> 
                  <span>
                    {label}
                  </span>                  
                </div>
              </Button>
            )
          }

          return (
            <div className={classes.item} key={index}>
              <TmpAction parent={parent} action={action} />
            </div>
          )
        })}
      </div>
    )
  }
}

TopActions.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onAjaxCall: (url, params, callback) => {
    dispatch(formActions.ajaxCall(url, params, callback))
  },
  onUpdateRelated: fields => {
    dispatch(blockActions.updateRelated(fields))
  },
  onShowMessage: messageConfig => {
    dispatch(messageActions.showMessage(messageConfig))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TopActions)))
