import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DashboardFields from '../../components/Dashboard'

import {
  dashboardActions
} from '../../actions'

import {
  appActions
} from '../../actions'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
    backgroundColor: theme.palette.background.default,
    marginTop: '-10px'
  },
})

let timeout = null

class Dashboard extends React.Component {
  refreshDashboard = () => {
    const { pageType, match } = this.props

    const { params } = match

    this.props.onDashboardGet(pageType, params)

    timeout = setTimeout(() => {
      this.refreshDashboard()
    }, (60 * 5 * 1000))
  }

  componentDidMount = () => {
    this.refreshDashboard()

    this.props.onChangeTitleBlock({
      breadcrumbs: []
    })
  }

  componentWillUnmount = () => {
    clearTimeout(timeout)
  }

  render() {
    const { dashboardConfig, classes } = this.props

    if (!dashboardConfig) {
      return ''
    }

    return (
      <div className={classes.root}>
        <DashboardFields items={dashboardConfig} />
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.dashboardReducer })

const mapDispatchToProps = dispatch => ({
  onDashboardGet: (pageType, params, activeLoading = true) => {
    dispatch(dashboardActions.dashboardGet(pageType, params, activeLoading))
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dashboard)))
