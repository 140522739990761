import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import MUIcon from '@material-ui/core/Icon'
import moment from 'moment'
import 'moment/locale/it'
import Icon from '../Icon'
import IconButton from '@material-ui/core/IconButton'
import _ from 'lodash'
import Preview from './Preview'
import Form from './Form'
import Search from './Search'
import { it } from 'react-date-range/dist/locale'
import { Calendar } from 'react-date-range'
import ModalActions from '../PageActions/ModalActions'
import CalendarEvent from './CalendarEvent'
import {
  agendaActions,
  printActions
} from '../../actions'

const days = [
  'Lun',
  'Mar',
  'Mer',
  'Gio',
  'Ven',
  'Sab',
  'Dom'
]

const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row'
    },
    calendarHeader: {
      display: 'flex',
      textAlign: 'center'
    },
    calendarDayName: {
      minWidth: '14.2857%',
      maxWidth: '14.2857%',
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderTop: '1px solid ' + theme.palette.border.calendar,
        borderLeft: '1px solid ' + theme.palette.border.calendar,
        borderRight: 0,
        zIndex: -1
      },
      '&:last-child': {
        '&::after': {
          borderRight: '1px solid ' + theme.palette.border.calendar,
        }
      }
    },
    calendarWeek: {
      display: 'flex',
      position: 'relative',
      zIndex: 1,
      '&:last-child': {
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -1,
          borderBottom: '1px solid ' + theme.palette.border.calendar,
          zIndex: 2
        }
      }
    },
    calendarWeekDay: {
      minWidth: '14.2857%',
      maxWidth: '14.2857%',
      height: 200,
      position: 'relative',
      zIndex: 1,
      marginBottom: -1,
      padding: '2rem .25rem .25rem',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderTop: '1px solid ' + theme.palette.border.calendar,
        borderLeft: '1px solid ' + theme.palette.border.calendar,
        zIndex: -1
      },
      '&:last-child': {
        '&::after': {
          borderRight: '1px solid ' + theme.palette.border.calendar,
        }
      }
    },
    calendarWeekDayEmpty: {
      backgroundColor: theme.palette.background.default
    },
    calendarDayCreation: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      cursor: 'crosshair'
    },
    calendarWeekDayLabel: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      padding: '0 .5rem',
      height: 30,
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 1
    },
    calendarWeekDayLabelActive: {
      fontWeight: 700,
      backgroundColor: theme.palette.primary.light,
      borderRadius: '50%',
      width: 23,
      height: 23,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.contrastText
    },
    calendarDayEvent: {
      padding: '.25rem .375rem .25rem .875rem',
      marginRight: '.5rem',
      borderRadius: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.default,
      marginBottom: '.25rem',
      position: 'relative',
      zIndex: 1,
      cursor: 'pointer',
      transition: 'all .125s ease-in-out',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '.5rem',
        bottom: 0,
        zIndex: -1
      },
      '&:hover': {
        boxShadow: theme.shadows[1]
      }
    },
    calendarDayEventTimetable: {
      backgroundColor: '#ffb300',
      color: theme.palette.common.black,
    },
    calendarDayEventMonitoringtimetable: {
      backgroundColor: '#8d6e63',
      color: theme.palette.common.white,
    },
    calendarDayEventCoursetimetable: {
      backgroundColor: '#78909c',
      color: theme.palette.common.white,
    },
    calendarTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5rem',
      height: 60,
      position: 'relative'
    },
    calendarTitleAction: {
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    calendarTitleMonth: {
      width: 350,
      textAlign: 'center',
      textTransform: 'capitalize',
      cursor: 'pointer'
    },
    calendarDayEventCompany: {
      backgroundColor: '#1565c0'
    },
    calendarDayEventCompanyWorker: {
      paddingLeft: '1.5rem',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'auto',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '1.5rem',
        bottom: 0,
        zIndex: -1
      },
      '&:hover': {
        boxShadow: 'none'
      }
    },
    calendarDayEventCompanyWorkerCheckbox: {
      '& > *:first-child': {
        backgroundColor: '#fff',
        borderRadius: 3,
      }
    },
    calendarDayEvents: {
      height: '100%',
      overflowY: 'auto',
      padding: '3px'
    },
    calendarTypeAction: {
      position: 'absolute',
      right: 0
    },
    info: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, .9)',
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 200,
      alignItems: 'center',
      textAlign: 'center',
      '& a': {
        fontSize: '1.25rem',
        color: theme.palette.grey[700]
      }
    },
    infoTitle: {
      fontSize: '2rem',
      maxWidth: 550,
      lineHeight: '1.2em',
      marginBottom: '2rem'
    },
    infoPaper: {
      padding: '4rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    infoIcon: {
      fontSize: '2.5rem',
      color: theme.palette.grey[600],
      marginBottom: '2rem',
      marginTop: '-2rem'
    },
    dayCalendarAllDayEvents: {
      padding: '8px 0 8px 56px'
    },
    dayCalendarHourBlock: {
      display: 'flex',
      padding: '.5rem 0',
      borderTop: '1px solid ' + theme.palette.border.calendar,
      minHeight: 50,
      position: 'relative'
    },
    dayCalendarHour: {
      borderRight: '1px solid ' + theme.palette.border.calendar,
      minWidth: 50,
      maxWidth: 50,
      paddingRight: '.5rem',
      textAlign: 'right',
    },
    dayCalendarHourEvents: {
      display: 'flex',
      padding: '.5rem',
      flexDirection: 'row',
      overflowX: 'auto',
    },
    calendarWeekDayAction: {
      padding: 0
    },
    calendarWeekDayActionIcon: {
      fontSize: '1rem'
    },
    calendarContainer: {
      display: 'none',
      position: 'absolute',
      zIndex: 3,
      backgroundColor: theme.palette.calendar.containerBackground,
      boxShadow: theme.shadows[1],
      top: '100%',
      marginTop: -8,
      '& .rdrCalendarWrapper': {
        backgroundColor: theme.palette.calendar.wrapperBackground,
        color: theme.palette.text.primary
      },
      '& .rdrMonthAndYearWrapper': {
        backgroundColor: theme.palette.calendar.monthYearWrapperBackground,  
        '& select': {
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },    
      },
      '& .rdrNextPrevButton': {
        backgroundColor: theme.palette.action.hover
      },
      '& .rdrPprevButton i': {
        borderColor: `transparent ${theme.palette.text.primary} transparent transparent !important`,
      },
      '& .rdrNextButton i': {
        borderColor: `transparent transparent transparent ${theme.palette.text.primary} !important`,
      },
      '& .rdrDay': {
        backgroundColor: 'transparent',
        '& .rdrDayNumber': {
          '& span': {
            color: `${theme.palette.text.default} !important`,
          }
        },  
        '& .rdrSelected': {
          '& ~ .rdrDayNumber span': {
            color: `${theme.palette.primary.contrastText} !important`,          
          }
        },
        '&.rdrDayPassive': {
          '& .rdrDayNumber span': {
            color: `${theme.palette.text.notClickable} !important`          
          }
        }    
      }
    },
    'calendar-tl': {
      top: 'auto',
      bottom: '100%',
      left: 0,
    },
    'calendar-tr': {
      top: 'auto',
      bottom: '100%',
      right: 50,
    },
    'calendar-bl': {},
    'calendar-br': {
      right: 50,
    },
    calendarContainerIsOpen: {
      display: 'block'
    },
    calendarToggler: {
      cursor: 'pointer'
    },
    calendarActions: {
      position: 'absolute',
      left: 0
    },
    withSearchOpen: {
      width: '80%',
      transition: 'width .125s ease-in-out'
    },
    withSearchClosed: {
      width: '100%',
      transition: 'width .125s ease-in-out'
    },
    searchClosed: {
      overflow: 'hidden',
      width: '0%',
      transition: 'width .125s ease-in-out'
    },
    search: {
      width: '20%',
      transition: 'width .125s ease-in-out'
    },
    datePickerContainer: {
      position: 'absolute',
      zIndex: 3,
      backgroundColor: theme.palette.calendar.containerBackground,
      boxShadow: theme.shadows[1],
      marginTop: 8,
      '& .rdrWeekDays': {
        display: 'none'
      },
      '& .rdrDays': {
        display: 'none'
      },
      '& .rdrCalendarWrapper': {
        backgroundColor: theme.palette.calendar.wrapperBackground,
        color: theme.palette.text.primary
      },
      '& .rdrMonthAndYearWrapper': {
        backgroundColor: theme.palette.calendar.monthYearWrapperBackground,  
        '& select': {
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },    
      },
      '& .rdrNextPrevButton': {
        backgroundColor: theme.palette.action.hover
      },
      '& .rdrPprevButton i': {
        borderColor: `transparent ${theme.palette.text.primary} transparent transparent !important`,
      },
      '& .rdrNextButton i': {
        borderColor: `transparent transparent transparent ${theme.palette.text.primary} !important`,
      },
      '& .rdrDay': {
        backgroundColor: 'transparent',
        '& .rdrDayNumber': {
          '& span': {
            color: `${theme.palette.text.default} !important`,
          }
        },  
        '& .rdrSelected': {
          '& ~ .rdrDayNumber span': {
            color: `${theme.palette.primary.contrastText} !important`,          
          }
        },
        '&.rdrDayPassive': {
          '& .rdrDayNumber span': {
            color: `${theme.palette.text.notClickable} !important`          
          }
        }    
      },   
    }
  }
}

class CalendarComponent extends React.Component {
  state = {
    weeks: [],
    doctors: [],
    type: 1,
    dayEvents: {},
    openCalendar: false,
    showDatePicker: false,
    selectedDate: new Date()
  }

  initAgenda = () => {
    const { agendaData } = this.props

    const { type = 1 } = agendaData

    const day = agendaData.day ? moment(agendaData.day, window.appConfig.dateFormat) : null

    const currentMonth = moment([
      1,
      agendaData.current.month,
      agendaData.current.year
    ].join('/'), 'D/M/YYYY')

    this.setState({
      type: type,
      day: day,
      doctors: agendaData.doctors,
      agendaConfig: agendaData.agendaConfig,
      current: day && type === 2 ? {
        month: parseInt(day.format('M')),
        year: parseInt(day.format('YYYY'))
      } : agendaData.current,
      periodFrom: moment(currentMonth).startOf('month').format(window.appConfig.dateFormat),
      periodTo: moment(currentMonth).endOf('month').format(window.appConfig.dateFormat)
    })

    if (type === 1) {
      return this.daysInMonth(agendaData.current)
    }

    this.dayEvents(agendaData.events)
  }

  componentDidMount() {
    this.initAgenda()

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showDatePicker: false, openCalendar: false })
    }
  }

  componentDidUpdate = prevProps => {
    const { agendaData: prevAgendaData, loadingData: prevLoadingData } = prevProps

    const { agendaData, loadingData } = this.props

    const { current = {} } = this.state

    if (prevLoadingData && !loadingData) {
      if (agendaData.type === 1) {
        return this.daysInMonth(agendaData.current)
      }

      this.dayEvents(agendaData.events)
    }

    if (agendaData.lastUpdate !== prevAgendaData.lastUpdate) {
      this.initAgenda()
    }

    if (agendaData.day !== prevAgendaData.day) {
      this.setState({
        day: agendaData.day ? moment(agendaData.day, window.appConfig.dateFormat) : null
      })
    }

    const newCurrent = agendaData.current || {}

    if (parseInt(current.month) !== parseInt(newCurrent.month) || parseInt(current.year) !== parseInt(newCurrent.year)) {
      this.setState({
        current: {
          month: parseInt(agendaData.current.month),
          year: parseInt(agendaData.current.year),
        }
      })
    }
  }

  orderEvents = (a, b) => {
    if (a.orderableDate > b.orderableDate) {
      return 1
    }

    if (a.orderableDate < b.orderableDate) {
      return -1
    }

    return 0
  }

  dayEvents = events => {
    const unorderedEvents = []

    const allDayEvents = []

    events.forEach(event => {
      if (event.time) {
        const eventOrderableDate = moment([
          event.date,
          event.time
        ].join(' '), window.appConfig.dateFormat + ' HH:mm')

        let currentH = eventOrderableDate.format('H')

        let currentIndex = 0

        const tmpEvents = [{
          ...event,
          orderableDate: eventOrderableDate,
          workerEvents: []
        }]

        if (event.workers) {
          event.workers.filter(worker => {
            return !(!worker.time)
          }).forEach(worker => {
            const workerOrderableDate = moment([
              event.date,
              worker.time
            ].join(' '), window.appConfig.dateFormat + ' HH:mm')

            if (workerOrderableDate.format('H') !== currentH) {
              currentIndex++

              tmpEvents.push({
                ...event,
                orderableDate: workerOrderableDate,
                workerEvents: []
              })
            }

            currentH = workerOrderableDate.format('H')

            return tmpEvents[currentIndex].workerEvents.push({
              ...worker,
              eventId: event.id,
              isWorker: true,
              title: [
                [
                  worker.worker.name,
                  worker.worker.cf
                ].join(' - '),
                [
                  '(',
                  worker.doctor.name,
                  ')'
                ].join('')
              ].join(' '),
              orderableDate: workerOrderableDate
            })
          })
        }

        return unorderedEvents.push(...tmpEvents)
      }

      allDayEvents.push(event)
    })

    unorderedEvents.sort(this.orderEvents)

    const hours = []

    for (let i = 0; i < 24; i++) {
      hours.push({
        hour: i,
        events: unorderedEvents.filter(event => {
          return parseInt(event.orderableDate.format('H')) === i && (!event.workers.length || event.workerEvents.length)
        })
      })
    }

    this.setState({
      dayEvents: {
        allDayEvents,
        hours
      }
    })
  }

  daysInMonth = current => {
    const { events } = this.props.agendaData

    const tmpDate = moment(this.getCurrentDate(current))

    const toReturn = []

    let dayOfWeek = tmpDate.day() - 1

    if (dayOfWeek === -1) {
      dayOfWeek = 6
    }

    let currentWeek = dayOfWeek ? 0 : -1

    for (let i = 0; i < tmpDate.daysInMonth(); i++) {
      const newDate = moment(tmpDate.format())

      newDate.set({
        hour: 15
      })

      newDate.add(i, 'days')

      let currentDayOfWeek = newDate.day() - 1

      if (currentDayOfWeek === -1) {
        currentDayOfWeek = 6
      }

      if (!currentDayOfWeek) {
        currentWeek++
      }

      if (!toReturn[currentWeek]) {
        toReturn[currentWeek] = []
      }

      toReturn[currentWeek].push(newDate)
    }

    this.setState({
      weeks: toReturn.map(item => {
        return item ? item.map(dayOfWeek => {
          const dayEvents = events.filter(event => {
            return event.date === dayOfWeek.format(window.appConfig.dateFormat)
          })

          dayEvents.sort((a, b) => {
            if (a.type === 'event') {
              return -1
            }

            if (b.type === 'event') {
              return 1
            }

            return 0
          })

          return {
            date: dayOfWeek,
            events: dayEvents
          }
        }) : null
      })
    })
  }

  getCurrentDateString = current => {
    return [
      current.year,
      (current.month < 10 ? '0' : '') + current.month,
      '01'
    ].join('-')
  }

  getCurrentDate = current => {
    return moment(this.getCurrentDateString(current))
  }

  toggleForm = (activeEvent) => {
    this.setState({
      openForm: !this.state.openForm
    }, () => {
      const newState = {
        openPreview: !this.state.openForm && this.state.activeEvent
      }

      if (activeEvent) {
        newState.activeEvent = activeEvent
      }

      this.setState(newState)
    })
  }

  onCreateEvent = (day, time) => {
    this.setState({
      day,
      time
    }, () => {
      this.toggleForm()
    })
  }

  onDeleteEvent = () => {
    const { current } = this.props.agendaData

    this.props.onAgendaDelete({
      current,
      id: this.state.activeEvent.id
    })
  }

  onViewEvent = activeEvent => {
    this.props.onAgendaSetActiveEvent(activeEvent)

    this.setState({
      activeEvent
    }, this.togglePreview)
  }

  onChangeMonth = (monthType, firstDay) => {
    const nextMonth = moment(firstDay.format())

    const {
      day,
      type,
      current
    } = this.state

    if (type === 1) {
      nextMonth[monthType](1, 'months')
    } else {
      day[monthType](1, 'days')
    }

    this.setState({
      type,
      day: day,
      current: type === 1 ? {
        month: parseInt(nextMonth.format('M')),
        year: parseInt(nextMonth.format('YYYY'))
      } : current
    }, () => {
      const {
        day,
        type,
        current
      } = this.state

      this.props.onAgendaDataGet({
        day: day ? day.format(window.appConfig.dateFormat) : null,
        type,
        selectedDoctors: this.props.selectedDoctors,
        current
      })
    })
  }

  togglePreview = () => {
    this.setState({
      openPreview: !this.state.openPreview
    }, () => {
      if (!this.state.openPreview) {
        this.setState({
          activeEvent: null
        })

        this.props.onAgendaSetActiveEvent(null)
      }
    })
  }

  onChange = data => {
    const {
      weeks,
      type,
      dayEvents,
      activeEvent
    } = this.state

    if (type === 1) {
      _.set(weeks, [
        ...data.path,
        'visited'
      ], data.visited)

      const workerPath = data.path[data.path.length - 1]

      activeEvent.workers[workerPath].visited = data.visited

      return this.setState({
        weeks,
        activeEvent
      })
    }

    const {
      eventInfo
    } = data

    let hour = null

    let findedEventIndex = -1

    dayEvents.hours.find((hourEvents, index) => {
      if (findedEventIndex === -1) {
        hour = index

        findedEventIndex = hourEvents.events.findIndex(event => {
          return event.id === eventInfo.workerId && event.eventId === eventInfo.eventId
        })
      }

      return findedEventIndex > -1
    })

    if (findedEventIndex > -1) {
      const tmpDayEvents = _.cloneDeep(dayEvents)

      tmpDayEvents.hours[hour].events[findedEventIndex].visited = data.visited

      return this.setState({
        dayEvents: tmpDayEvents
      })
    }
  }

  onSwitchType = (type, day) => {
    day = type === 2 ? (day || moment()) : (day || this.state.day || null)

    const {
      current,
      type: stateType
    } = this.state

    const currentMonth = parseInt(current.month)

    const currentYear = parseInt(current.year)

    const dayMonth = parseInt(day.format('M'))

    const dayYear = parseInt(day.format('YYYY'))

    if (stateType !== type && type === 2 && (currentMonth !== dayMonth || currentYear !== dayYear)) {
      day = moment([current.year, current.month, '1'].join('-'), 'YYYY-M-D')
    }

    this.setState({
      type,
      day
    }, () => {
      const {
        day,
        type
      } = this.state

      this.props.onAgendaDataGet({
        type,
        day: day.format(window.appConfig.dateFormat),
        selectedDoctors: this.props.selectedDoctors,
        current: !day ? current : {
          month: day.format('M'),
          year: day.format('YYYY')
        }
      })
    })
  }

  getDoctorIndex = event => {
    const {
      doctors,
    } = this.state

    const doctorId = event.isWorker ? event.doctor.id : event.doctor

    return doctors.findIndex(item => item.id === doctorId) || -1
  }

  getTimeEvent = event => {
    const {
      classes,
      queryString
    } = this.props

    return (
      <div className={classes.dayCalendarHourEventContainer}>
        <div className={classes.dayCalendarHourEvent}>
          <CalendarEvent event={event} getDoctorIndex={this.getDoctorIndex} onViewEvent={this.onViewEvent} queryString={queryString} day={this.state.day} />
        </div>
        {event.workerEvents ? event.workerEvents.map((workerEvent, workerIndex) => {
          return (
            <div key={'worker-' + workerIndex}>{this.getTimeEvent(workerEvent)}</div>
          )
        }) : ''}
      </div>
    )
  }

  handleClick = (action, parentAction, actionParams = {}) => {
    let onFiltered = action.onFiltered || false

    if (parentAction) {
      onFiltered = parentAction.onFiltered
    }

    const { inlineForm = {} } = this.props

    const { periodFrom, periodTo, openModal } = this.state

    let params = {
      ...actionParams
    }

    switch (action.type) {
      case 'print':
        if (openModal) {
          params.period = {
            from: periodFrom,
            to: periodTo
          }
        }

        Object.values(inlineForm).forEach(formParams => {
          params = {
            ...params,
            ...formParams
          }
        })

        if (onFiltered) {
          return this.props.onGetFilteredItems((callbackAction, callbackParams) => {
            const { selectedElements, filteredItems } = this.props

            return this.props.onPrintPost(callbackAction, {
              ...callbackParams,
              id: filteredItems ? filteredItems.map(item => item.id) : Object.keys(selectedElements)
            })
          }, [action.action, params])
        }

        return this.props.onPrintPost(action.action, params)
      default:
        return this.setState({
          openModal: true,
          currentModalAction: {
            ...action,
            onFiltered: onFiltered
          }
        })
    }
  }
    
  handleDateChange = (date) => {
    const newDate = moment(date)

    const isMonthView = this.state.type === 1

    this.setState({
      selectedDate: newDate.toDate(),
      showDatePicker: false,
      current: {
        month: newDate.month() + 1,
        year: newDate.year(),
      },
      day: isMonthView ? null : newDate
    }, () => {
      this.props.onAgendaDataGet({
        current: {
          month: newDate.month() + 1,
          year: newDate.year(),
        },
        day: isMonthView ? null : newDate.format(window.appConfig.dateFormat),
        type: this.state.type,
      })
    })
  }

  onDateChangeRaw = (date, event) => {
    event.preventDefault()
  }

  render() {
    const {
      weeks,
      activeEvent,
      openPreview,
      openForm,
      day,
      doctors,
      agendaConfig,
      type,
      dayEvents,
      time = null,
      openCalendar,
      openModal,
      showDatePicker,
      selectedDate
    } = this.state

    moment.locale('it')

    if (!weeks.length && type === 1) {
      return ''
    }

    const { classes, agendaData, queryString, previewedEvent } = this.props

    const { current, workerHelper } = agendaData

    const firstDay = moment(this.getCurrentDate(current))

    return (
      <div className={classes.root}>
        {(weeks.length || type !== 1) && (
          <div className={queryString ? classes.withSearchOpen : classes.withSearchClosed}>
            {agendaData.topActions && (
              <ModalActions currentModalAction={agendaData.topActions} openModal={openModal} parent={this} onClose={() => {
                this.setState({
                  openModal: false
                })
              }} onClick={this.handleClick} />
            )}
            {!agendaData.isActive ? (
              <div className={classes.info}>
                <Paper className={classes.infoPaper}>
                  <MUIcon className={classes.infoIcon}>lock</MUIcon>
                  <div className={classes.infoTitle}>Gestisci le tue attività e scadenze con la nuova vista a calendario.</div>
                  <a href={agendaData.infoUrl} target='_blank' rel='noopener noreferrer'>Per saperne di più clicca qui.</a>
                </Paper>
              </div>
            ) : ''}
            <Form companyHelper={agendaData.companyHelper} {...{
              current,
              doctors,
              activeEvent,
              openForm,
              day,
              time,
              workerHelper,
              agendaConfig,
              type,
              confirms: agendaData.confirms,
              onClose: activeEvent => this.toggleForm(activeEvent)
            }} />
            <Preview {...{
              activeEvent: previewedEvent || activeEvent,
              openPreview,
              onClose: this.togglePreview,
              onDelete: this.onDeleteEvent,
              onEdit: () => this.toggleForm(),
              onChange: this.onChange,
              doctors
            }} />
            <div className={classes.calendarTitle}>
              {agendaData.topActions ? (
                <div className={classes.calendarActions}>
                  <IconButton color={'default'} onClick={() => {
                    this.setState({
                      openModal: true
                    })
                  }}>
                    <MUIcon>print</MUIcon>
                  </IconButton>
                </div>
              ) : ''}
              <div className={classes.calendarTitleAction} onClick={() => {
                return this.onChangeMonth('subtract', firstDay)
              }}>
                <Icon>chevron-left</Icon>
              </div>
              <div className={classes.calendarTitleMonth}>                
                {type === 1 ? (
                  <div onClick={() => this.setState({ showDatePicker: !showDatePicker })} className={classes.calendarToggler}>
                    {firstDay.format('MMMM YYYY')}
                  </div>
                ) : (
                  <div>
                    <div onClick={() => this.setState({ openCalendar: !openCalendar })} className={classes.calendarToggler}>
                      {day.format('DD MMMM YYYY')}
                    </div>
                    <div ref={this.setWrapperRef} className={[
                      classes.calendarContainer,
                      openCalendar ? classes.calendarContainerIsOpen : '',
                      classes['calendar-bl']
                    ].join(' ')}>
                      <Calendar
                        date={day.toDate()}
                        locale={it}
                        onChange={(date) => {
                          const tmpDay = moment(date)

                          this.setState({
                            day: tmpDay,
                            openCalendar: false
                          })

                          this.onSwitchType(2, tmpDay)
                        }} />
                    </div>
                  </div>
                )}
                {showDatePicker && (
                  <div ref={this.setWrapperRef} className={classes.datePickerContainer}>
                    <Calendar
                      date={selectedDate}
                      locale={it}
                      onChange={this.handleDateChange}
                      onDateChangeRaw={this.onDateChangeRaw}
                      onShownDateChange={this.handleDateChange}
                    />
                  </div>
                )}
              </div>
              <div className={classes.calendarTitleAction} onClick={() => {
                return this.onChangeMonth('add', firstDay)
              }}>
                <Icon>chevron-right</Icon>
              </div>
              <div className={classes.calendarTypeAction}>
                <IconButton color={type === 1 ? 'primary' : 'default'} onClick={() => {
                  if (type !== 1) {
                    this.onSwitchType(1)
                  }
                }}>
                  <MUIcon>apps</MUIcon>
                </IconButton>
                <IconButton color={type === 2 ? 'primary' : 'default'} onClick={() => {
                  if (type !== 2) {
                    this.onSwitchType(2)
                  }
                }}>
                  <MUIcon>view_list</MUIcon>
                </IconButton>
              </div>
            </div>
            {type === 1 ? (
              <div className={classes.calendarRoot}>
                <div className={classes.calendarHeader}>
                  {days.map((day, index) => {
                    return (
                      <div className={classes.calendarDayName} key={index}>{day}</div>
                    )
                  })}
                </div>
                {weeks.map((weekDays, index) => {
                  const emptyDays = Array.from(Array(7 - weekDays.length).keys())

                  return (
                    <div className={classes.calendarWeek} key={index}>
                      {!index ? emptyDays.map((emptyDay, emptyDayIndex) => {
                        return (
                          <div className={[
                            classes.calendarWeekDay,
                            classes.calendarWeekDayEmpty
                          ].join(' ')} key={emptyDayIndex}>
                          </div>
                        )
                      }) : ''}
                      {weekDays.map((weekDay, dayIndex) => {
                        const today = moment()

                        weekDay.events.sort((a, b) => {
                          if (!a.time) {
                            return 1
                          }

                          const aDate = moment([
                            a.date,
                            a.time
                          ].join(' '), 'DD/MM/YYYY HH:mm')

                          const bDate = moment([
                            b.date,
                            b.time
                          ].join(' '), 'DD/MM/YYYY HH:mm')

                          if (aDate > bDate) {
                            return 1
                          }

                          if (aDate < bDate) {
                            return -1
                          }

                          return 0
                        })

                        return (
                          <div className={classes.calendarWeekDay} key={dayIndex}>
                            <div className={classes.calendarWeekDayLabel}>
                              <div className={[
                                weekDay.date.format('YYYY-MM-DD') === today.format('YYYY-MM-DD') ? classes.calendarWeekDayLabelActive : ''
                              ].join(' ')}>
                                {weekDay.date.format('D')}
                              </div>
                              <div className={classes.calendarWeekDayAction}>
                                <IconButton className={classes.calendarWeekDayAction} onClick={() => {
                                  this.onSwitchType(2, weekDay.date)
                                }}>
                                  <MUIcon fontSize='small' classes={{
                                    root: classes.calendarWeekDayActionIcon
                                  }}>view_list</MUIcon>
                                </IconButton>
                              </div>
                            </div>
                            <div className={classes.calendarDayCreation} onClick={() => {
                              return this.onCreateEvent(weekDay.date)
                            }}></div>
                            <div className={classes.calendarDayEvents}>
                              {weekDay.events.map((dayEvent, dayEventIndex) => {
                                return (
                                  <CalendarEvent key={dayEventIndex} day={this.state.day} queryString={queryString} event={dayEvent} eventIndex={dayEventIndex} dayIndex={dayIndex} weekIndex={index} getDoctorIndex={this.getDoctorIndex} onViewEvent={this.onViewEvent} />
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                      {index ? emptyDays.map((emptyDay, emptyDayIndex) => {
                        return (
                          <div className={[
                            classes.calendarWeekDay,
                            classes.calendarWeekDayEmpty
                          ].join(' ')} key={emptyDayIndex}>
                          </div>
                        )
                      }) : ''}
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className={classes.dayCalendarRoot}>
                {dayEvents.allDayEvents ? (
                  <div className={classes.dayCalendarAllDayEvents}>
                    {dayEvents.allDayEvents.map((allDayEvent, index) => {
                      return (
                        <div key={index}>
                          {this.getTimeEvent(allDayEvent)}
                        </div>
                      )
                    })}
                  </div>
                ) : ''}
                {(dayEvents.hours || []).map((hour, hourIndex) => {
                  const currentTime = [
                    (hour.hour < 10 ? '0' : ''),
                    hour.hour,
                    ':00'
                  ].join('')

                  return (
                    <div className={classes.dayCalendarHourBlock} key={hourIndex}>
                      <div className={classes.dayCalendarHour}>
                        {hour.hour}
                      </div>
                      <div className={classes.dayCalendarHourEvents}>
                        <div className={classes.calendarDayCreation} onClick={() => {
                          return this.onCreateEvent(day, currentTime)
                        }}></div>
                        {hour.events.map((event, eventIndex) => {
                          return (
                            <div key={eventIndex}>{this.getTimeEvent(event, eventIndex)}</div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}
        <div className={queryString ? classes.search : classes.searchClosed}>
          <Search day={day} current={current} type={type} selectedDoctors={this.props.selectedDoctors} queryString={queryString} />
        </div>
      </div>
    )
  }
}

CalendarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.agendaReducer })

const mapDispatchToProps = dispatch => ({
  onAgendaDataGet: params => {
    dispatch(agendaActions.agendaDataGetCall(params))
  },
  onAgendaFiltersGet: params => {
    dispatch(agendaActions.agendaFiltersGetCall(params))
  },
  onAgendaDelete: params => {
    dispatch(agendaActions.agendaDelete(params))
  },
  onUpdateItem: (path, props) => {
    dispatch(agendaActions.updateItem(path, props))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
  onWorkerAgendaSave: (data) => {
    dispatch(agendaActions.workerAgendaSave(data))
  },
  onAgendaSetActiveEvent: (data) => {
    dispatch(agendaActions.agendaSetActiveEvent(data))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CalendarComponent)))
