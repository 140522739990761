import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'

import {} from '../../actions'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.loading,
    zIndex: '99999999'
  },
  updateItem: {
    width: 700,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    position: 'relative'
  },
  updatePercentage409: {
    width: 700,
    height: 3
  },
  updatePercentage: {
    flexGrow: 1
  },
  updateIcon: {
    marginRight: '1rem',
    fontSize: '3rem'
  },
  text: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2rem',
    marginBottom: '2rem'
  },
  updatePercentagesLabel: {
    position: 'absolute',
    top: 20,
    left: 64,
  }
})

class Loading extends React.Component {
  render() {
    const { classes, updatePercentages, from409 } = this.props

    const items = ['up', 'down']

    return (
      <div className={classes.root}>
        <div className={classes.text}>
          <strong>Aggiornamento in corso...</strong>
          Si prega di non chiudere Achille© fino al termine della procedura
        </div>
        {from409 ? <LinearProgress className={classes.updatePercentage409} /> : items.map((item, index) => {
          const currentItem = updatePercentages[item]

          const buffer = currentItem === 100 ? 100 : (currentItem.buffer || 100)

          const percentage = currentItem === 100 ? 100 : (currentItem.percentage || 0)

          const icon = 'cloud_' + item + 'load'

          return (
            <div className={classes.updateItem} key={index}>
              <Icon className={classes.updateIcon}>{icon}</Icon>
              {currentItem.model ? (
                <div className={classes.updatePercentagesLabel}>{currentItem.model || ''}: {currentItem.from}/{currentItem.to}</div>
              ) : null}
              <LinearProgress className={classes.updatePercentage} variant='buffer' value={percentage || 0} valueBuffer={buffer} />
            </div>
          )
        })}
      </div>
    )
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.updateReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Loading)))
