import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MUIRadio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flexGrow: '1',
  },
  group: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  labelRoot: {
    marginLeft: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: '.75rem',
    textTransform: 'uppercase'
  },
  checkbox: {
    padding: 0
  }
})

class Radio extends React.Component {
  state = {}

  componentDidMount = () => {
    const { value, options } = this.props.cell

    this.setState({
      value: value || options[0].value
    })
  }
  componentDidUpdate = () => {
    const { value } = this.props.cell

    if (value !== this.state.value) {
      this.setState({
        value
      })
    }
  }

  render() {
    const { onChange, fieldPath, classes, cell, isActive, readonly } = this.props

    const { tableControl, options, unselected } = cell

    const { value } = this.state

    const disabled = !isActive ? !tableControl ? true : false : false

    const realValue = value ? value : (unselected ? null : options[0].value)

    return (
      <div className={classes.root}>
        <RadioGroup
          aria-label='Gender'
          name='gender1'
          className={classes.group}
          value={'' + realValue}
          onChange={(event) => {
            onChange(event.target.value, fieldPath)

            this.setState({
              value: event.target.value
            })
          }} >
          {options.map((element, index) => {
            const isDisabled = readonly || disabled || (cell.readonly ? true : false)

            const isChecked = !isDisabled && '' + element.value === value

            const checkedProp = {}

            if (cell.clearable || isDisabled) {
              checkedProp.checked = isChecked
            }

            return (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.label
                }}
                key={index}
                value={'' + element.value}
                control={
                  <MUIRadio
                    disabled={isDisabled}
                    {...checkedProp}
                    classes={{
                      root: classes.checkbox
                    }}
                    color='primary' />}
                label={'' + element.label} />
            )
          })}
        </RadioGroup>
      </div>
    )
  }
}

Radio.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Radio)))
