import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import autosize from 'autosize'

import { } from '../../actions'

const styles = theme => ({
  rootContainer: {
    flexGrow: 1,
    marginBottom: 0,
    marginTop: 0,
    position: 'relative'
  },
  textField: {
    marginBottom: 0,
    marginTop: 0,
  },
  inputClass: {
    backgroundColor: theme.palette.background.formfields,
  },
  inputBorderOrange: {
    '& fieldset': {
      borderColor: theme.palette.warning.main + '!important'
    }
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -8,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, 0px)',
    padding: '0 3px'
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  },
  smallSelect: {
    position: 'absolute',
    zIndex: 1,
    top: 2,
    left: 2,
    right: 2,
    width: 'auto'
  },
  smallSelectOpen: {
    zIndex: 2
  },
  multiline: {
    padding: '.75rem',
    backgroundColor: theme.palette.background.formfields,
  },
  labelShrink: {
    backgroundColor: theme.palette.background.muiPaper
  }
})

class ComboText extends React.Component {
  state = {
    error: false,
    errorMessage: null,
    focused: false,
    selectValue: {},
    textValue: '',
    menuIsOpen: false,
    openMenuEvent: null,
    ref: null
  }

  toggleMenuOpen = isOpen => {
    const {
      menuIsOpen,
      openMenuEvent
    } = this.state

    if (isOpen !== menuIsOpen) {
      const changeOpenMenu = setTimeout(() => {
        this.setState({
          menuIsOpen: isOpen
        })
      }, 10)

      if (openMenuEvent) {
        clearTimeout(openMenuEvent)

        this.setState({
          openMenuEvent: null
        }, () => {
          this.setState({
            openMenuEvent: changeOpenMenu
          })
        })
      }
    }
  }

  customStyles = (config, theme) => {
    const {
      menuIsOpen
    } = this.state

    const {
      customStyles: newCustomStyles
    } = config

    const defaultCustomStyles = {
      control: () => ({}),
      menu: () => ({}),
      dropdownIndicator: () => ({}),
      singleValue: () => ({}),
      valueContainer: () => ({}),
      indicatorSeparator: () => ({}),
      indicatorsContainer: () => ({}),
      option: () => ({}),
      input: () => ({}),
      ...newCustomStyles
    }

    return {
      container: provided => {
        return {
          ...provided,
          ...(
            !menuIsOpen ? {
              height: 5,
            } : {}
          )
        }
      },
      control: (provided, state) => {
        this.toggleMenuOpen(state.menuIsOpen)

        return {
          ...provided,
          borderStyle: 'solid',
          borderWidth: state.isFocused ? 2 : 1,
          borderColor: config.error ? theme.palette.error.main : (state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover),
          border: 0,
          backgroundColor: theme.palette.background.controlSelect,
          margin: 0,
          minHeight: 41,
          padding: '0 14px',
          boxShadow: 'none',
          transition: 'height .25s ease-in-out',
          '&:hover': {
            borderColor: config.error ? theme.palette.error.main : theme.palette.select.inputControlBorder,
          },
          ...defaultCustomStyles.control(provided, state),
          ...(
            !state.menuIsOpen ? {
              height: 5,
              minHeight: 0
            } : {}
          )
        }
      },
      menu: (provided, state) => ({
        ...provided,
        zIndex: 100,
        marginTop: -5,
        backgroundColor: theme.palette.select.inputControlMenuListBackground,
        ...defaultCustomStyles.menu(provided, state)
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        ...defaultCustomStyles.dropdownIndicator(provided, state)
      }),
      singleValue: (provided, state) => ({
        ...provided,
        margin: 0,
        color: config.error ? theme.palette.error.main : provided.color,
        ...defaultCustomStyles.singleValue(provided, state),
      }),
      valueContainer: (provided, state) => {
        return {
          ...provided,
          paddingLeft: 0,
          fontSize: '0.9285714285714286rem',
          color: config.error ? theme.palette.error.main : provided.color,
          transition: 'height .25s ease-in-out',
          ...defaultCustomStyles.valueContainer(provided, state),
          ...(
            !menuIsOpen ? {
              height: 0,
            } : {}
          )
        }
      },
      indicatorContainer: provided => ({
        ...provided
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        position: 'absolute',
        right: 0,
        paddingBottom: 40,
        ...defaultCustomStyles.indicatorsContainer(provided, state)
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        margin: 0,
        border: 0,
        backgroundColor: 'transparent',
        ...defaultCustomStyles.indicatorSeparator(provided, state),
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: theme.palette.select.inputControlMenuListBackground,
        color: theme.palette.select.text,
        cursor: 'pointer',
        '&:active': {
          backgroundColor: theme.palette.select.inputControlElementSelected,
        },
        '&:hover': {
          backgroundColor: theme.palette.select.inputControlElementHover,
        },
        ...defaultCustomStyles.option(provided, state)
      }),
      input: (provided, state) => ({
        ...provided,
        color: config.error ? theme.palette.error.main : theme.palette.text.primary,
        ...defaultCustomStyles.input(provided, state)
      })
    }
  }

  componentDidMount = () => {
    const { value, options } = this.props.field

    const selectValue = options.filter(option => {
      return option.value === value
    })[0] || {}

    this.setState({
      selectValue,
      textValue: value || (selectValue || {}).value || ''
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { field, onChange, lastUpdate } = this.props

    const { lastUpdate: prevLastUpdate } = prevProps

    let { value } = field

    if (value === null) {
      value = ''
    }

    const {
      textValue,
      ref
    } = this.state

    const {
      textValue: preTextValue,
      re: prevRef
    } = prevState

    const fromState = textValue !== preTextValue

    const fromProp = prevLastUpdate !== lastUpdate

    if (!prevRef && ref) {
      autosize(ref)
    }

    if (!fromState) {
      if (fromProp && value !== textValue) {
        this.setState({
          selectValue: field.options.find(option => {
            return option.value === value
          }),
          textValue: value
        }, () => {
          this.triggerChange(ref)
        })
      }
    } else if (textValue !== value) {
      onChange({ type: 'string', fromState: true }, field, textValue)
    }
  }

  triggerChange = ref => {
    const e = new Event('input')

    ref.dispatchEvent(e)
  }

  render() {
    const { classes, field, selectClasses, newCustomStyles, noOptionsMessage, readonly, theme } = this.props

    const {
      options,
      multiline,
      rows = 1,
    } = field

    const {
      selectValue,
      textValue,
      ref
    } = this.state

    const newClasses = {
      ...classes,
      ...selectClasses
    }

    const {
      focused,
      menuIsOpen
    } = this.state

    const labelActive = (focused || textValue) ? ' ' + classes.labelActive : ''

    const fieldReadonly = readonly || field.readonly

    return (
      <div className={newClasses.rootContainer}>
        <FormControl fullWidth={true} error={field.error ? true : false} className={[
          newClasses.root,
          newClasses.smallSelect,
          menuIsOpen ? newClasses.smallSelectOpen : ''
        ].filter(item => !(!item)).join(' ')}>
          {field.label ? (
            <InputLabel variant='outlined' className={newClasses.label + labelActive}>{field.label}</InputLabel>
          ) : ''}
          {options.length ? (
            <Select
              variant='outlined'
              styles={this.customStyles({
                error: field.error ? true : false,
                customStyles: newCustomStyles
              }, theme)}
              isDisabled={fieldReadonly || field.disabled}
              options={options}
              defaultValue={selectValue || ''}
              inputId={_.uniqueId()}
              placeholder={field.placeholder || false}
              isMulti={false}
              value=''
              isClearable={field.isClearable ? true : false}
              onBlur={() => this.setState({ focused: false })}
              onFocus={() => this.setState({ focused: true })}
              onChange={(currentValue) => {
                const currentTextValues = currentValue.value || ''

                const value = field.append ? [textValue, currentTextValues].filter(item => !(!item)).join('\n') : currentTextValues

                return this.setState({
                  selectValue: currentValue,
                  textValue: value
                }, () => {
                  this.triggerChange(ref)
                })
              }}
              noOptionsMessage={() => noOptionsMessage ? noOptionsMessage.noResults : null} />
          ) : ''}
        </FormControl>
        <TextField
          id={field.name}
          name={field.name}
          className={classes.textField}
          InputProps={{
            classes: {
              root: [
                classes.input,
                classes['input' + (selectValue || {}).optionStyle]
              ].filter(item => !(!item)).join(' '),
              input: classes.inputClass,
              notchedOutline: classes.input,
              multiline: classes.multiline
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.labelShrink,
            }
          }}
          inputRef={ref => {
            return this.setState({
              ref
            })
          }}
          disabled={fieldReadonly}
          multiline={multiline || false}
          rows={rows}
          fullWidth
          variant='outlined'
          onFocus={(e) => {
            this.setState({ focused: true })

            this.triggerChange(e.target)
          }}
          onBlur={(e) => {
            this.setState({ focused: false })

            this.triggerChange(e.target)
          }}
          onChange={(event) => {
            autosize(event.target)

            const value = event.target.value

            if (value !== textValue) {
              this.setState({
                selectValue: field.options.find(option => {
                  return option.value === value
                }) || {},
                textValue: value || ''
              })
            }
          }}
          margin='normal'
          error={this.state.error}
          helperText={this.state.errorMessage}
          value={textValue} />
      </div>
    )
  }
}

ComboText.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.formReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ComboText)))
