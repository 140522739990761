import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import logoLight from '../../assets/img/logo_light.svg'
import logoDark from '../../assets/img/logo_dark.svg'

const styles = () => ({
  root: {
    marginBottom: '1rem'
  },
  title: {
    textAlign: 'center',
    marginBottom: '.3rem'
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0
  },
  img: {
    width: 350
  }
})

class HeaderLogin extends React.Component {
  render() {
    const { classes } = this.props
    const themePreference = localStorage.getItem('themePreference') || 'system'

    const isDarkMode = themePreference === 'system' ? window.matchMedia('(prefers-color-scheme: dark)').matches : themePreference === 'dark'

    const logo = isDarkMode ? logoDark : logoLight

    return (
      <div className={classes.root}>
        <h1 className={classes.title}>
          <img className={classes.img} src={logo} alt={'Achille'} />
        </h1>
      </div>
    )
  }
}

HeaderLogin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(HeaderLogin)
