import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { InputAdornment, FormHelperText } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { validators } from './Validators'
import SpeechRecognitionComponent from '../SpeechRecognition'
import WarningIcon from '@material-ui/icons/Warning'
import LabelWithAsterisk from './LabelWithAsterisk'

const styles = theme => ({
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 11px) !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  input: {
    padding: '.75rem'
  },
  labelShrink: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -7px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  inputReadOnly: {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.background.readOnly
  },
  textField: {
    marginTop: 0,
  },
  infoIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginRight: theme.spacing.unit,
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85rem',
    marginTop: theme.spacing.unit,
  },
  errorText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85rem',
    marginTop: theme.spacing.unit,
    color: theme.palette.error.main,
  }
})

class Text extends React.Component {
  textRef = null

  state = {
    error: false,
    errorMessage: null,
    stringValue: '',
    tmpStringValue: '',
    speechError: null,
    supported: true,
    isListening: false
  }

  componentDidMount() {
    const { value } = this.props.field

    this.setState({
      stringValue: value || '',
      tmpStringValue: value || ''
    })

    return validators.mount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { stringValue } = this.state

    const { stringValue: preStringValue } = prevState

    const fromState = stringValue !== preStringValue

    const { field, onChange } = this.props

    let { value } = field

    value = value || ''

    if (!fromState && value !== stringValue) {
      this.setState({
        stringValue: value || ''
      }, () => {
        this.textRef.scrollTop = this.textRef.scrollHeight

        onChange({type: 'string'}, field, value)
      })
    }

    return validators.update(this, prevProps)
  }

  onChange = (event) => {
    const { field, onChange } = this.props

    const { tmpStringValue } = this.state

    const { value, type = 'string', actionType, isFinal } = event.target

    const newState = {
      stringValue: value
    }

    if (actionType === 'speech') {
      newState.stringValue = `${tmpStringValue} ${value}`

      if (isFinal) {
        newState.tmpStringValue = newState.stringValue
      }

      field.value = newState.stringValue
    } else {
      newState.tmpStringValue = newState.stringValue
    }

    this.setState(newState)

    return onChange({type}, field, value)
  }

  handleSpeechError = (error) => {
    this.setState({ speechError: error })
  }

  setSupported = (supported) => {
    this.setState({ supported })
  }

  setIsListening = (isListening) => {
    this.setState({ isListening })
  }

  render() {
    const { classes, field, readonly } = this.props

    const { rows = 5 } = field

    const { stringValue, speechError, supported, isListening } = this.state

    const fieldReadonly = readonly || field.readonly || isListening

    const helperText = speechError ? (
      <FormHelperText className={classes.errorText}>
        <WarningIcon className={classes.infoIcon} />
        {speechError === 'not-allowed' ? 'Permessi del microfono non abilitati. Per favore abilita il microfono nelle impostazioni del browser.' : speechError}
      </FormHelperText>
    ) : (
      <FormHelperText className={classes.helperText}>
        <InfoIcon className={classes.infoIcon} />
        {field.speechHelper}
      </FormHelperText>
    )

    return (
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}> 
        <TextField
          id={field.name}
          name={field.name}
          label={<LabelWithAsterisk>{field.label}</LabelWithAsterisk>}
          className={classes.textField}
          InputProps={{
            classes: {
              root: classes.input + (fieldReadonly ? ' ' + classes.inputReadOnly : '')
            },
            readOnly: fieldReadonly,
            inputProps: {
              ref: textRef => this.textRef = textRef
            },
            endAdornment: field.speech && supported?
              <InputAdornment position='end'>
                <SpeechRecognitionComponent
                  options={{continuous: false}}
                  field={field}
                  onChange={this.onChange}
                  handleSpeechError={this.handleSpeechError}
                  setSupported={this.setSupported}
                  isListening={isListening}
                  setIsListening={this.setIsListening}
                />
              </InputAdornment>
              : null
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.labelShrink,
            }
          }}
          multiline={true}
          rows={rows}
          fullWidth
          variant='outlined'
          onChange={this.onChange}
          margin='normal'
          error={field.error ? true : false}
          helperText={field.error ? field.error : field.snippet || ''}
          value={stringValue}
        />
        {field.speech && supported && helperText}
      </div>
    )
  }
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Text)))